import Cell from "./cell";
import { MAX_Q, MAX_R, MAX_S, MIN_Q, MIN_R, MIN_S } from "./constants";
import { Game } from "./game";
import { GLOSSARY, UNLOCKABLE_TYPES } from "./glossary";
import Grid from "./grid";
import { choose, clamp, irange, repeat, shuffle, sleep } from "./util";

export const CURIOSITIES = {
  gaps: {
    generate: () => {
      // Pure chaos approach! :)
      const earth = Grid.filter(
        (x) => x.glossaryType === "air" && Math.random() > 0.5
      );
      for (const cell of earth) {
        cell.set("earth");
      }
    },
    hand: [
      "Barrier",
      "Barrier",
      "Barrier",
      "Bridge",
      "Bridge",
      "Bridge",
      "Bridge",
    ],
    hexes: 7,
  },
  sprint: {
    generate: () => {
      // Create central vertical stripe
      for (let r = MIN_R + 1; r <= MAX_R - 1; ++r) {
        Grid.set(0, r, new Cell());
      }

      // Create some flavor that can't be reached
      Grid.filter((x) => Math.abs(x.q) >= 2).forEach((x) =>
        x.set(choose(["fire", "water", "earth", "air"]))
      );

      // Create some bridges for added pressure
      shuffle(Grid.filter((x) => Math.abs(x.q) === 1))
        .slice(0, 3)
        .forEach((x) => x.set("Bridge"));
    },
    hand: ["Horse", "Horse", "Horse"],
    // hexes: 6,
  },
  treasure: {
    generate: () => {
      // Grid
      //   .filter(x => x.glossaryType === 'air' && Math.random() > 0.25)
      //   .forEach(x => x.set('earth'));
      // shuffle(Grid.filter(x => x.glossaryType === 'air' &&
      //   !x.adjacentCells.some(x => x.element === 'castle')
      // ))
      //   .slice(0, 3)
      //   .forEach(x => x.set('Treasure'));

      // Create horizontal stripes of top/bottom
      for (const r of [MIN_R, MAX_R]) {
        for (let q = MIN_Q + 1; q <= MAX_Q - 1; ++q) {
          if (Math.abs(q) <= 1) {
            continue;
          }
          let adjustedR = r;
          if (-q - r < MIN_S) {
            adjustedR = MAX_S - q;
          } else if (-q - r > MAX_S) {
            adjustedR = MIN_S - q;
          }
          let cell;
          if (r < 0) {
            cell = new Cell("Treasure");
          } else {
            cell = new Cell("Barrier", { deck: Game.player });
          }
          Grid.set(q, adjustedR, cell);
        }
      }

      // Randomly replace some treasure with gaps
      // shuffle(Grid.filter(x => x.type === 'Treasure')).slice(0, 3).forEach(x => x.set(null));

      // Create central vertical stripe
      for (let r = MIN_R + 1; r <= MAX_R - 1; ++r) {
        Grid.set(0, r, new Cell());
      }

      // // Create meandering paths
      // for (let cell of Grid.filter(x => x.type === 'Barrier')) {
      //   for (let i = 12; i --; ) {
      //     const next = choose((Math.random() < 0.75 ? [
      //       cell.above,
      //     ] : [
      //       cell.above,
      //       cell.upperLeft,
      //       cell.upperRight,
      //     ]).filter(x => !x?.type));
      //     if (!next) break;
      //     cell = next;
      //     cell.set('earth');
      //   }
      // }

      // Create a bit of chaos
      Grid.filter((x) => x.glossaryType === "air").forEach((x) =>
        x.set("earth")
      );

      // Create meandering paths
      for (let cell of Grid.filter((x) => x.type === "Treasure")) {
        for (let i = 12; i--; ) {
          const next = choose(
            (Math.random() < 0.35
              ? [cell.below]
              : [
                  cell.below,
                  // cell.lowerLeft,
                  // cell.lowerRight,
                  cell.q === -1
                    ? cell.lowerLeft
                    : cell.q === 1
                    ? cell.lowerRight
                    : cell.q < 0
                    ? cell.lowerRight
                    : cell.lowerLeft,
                ]
            ).filter((x) => !x?.type)
          );
          if (!next) break;
          cell = next;
          cell.set("air");
        }
      }

      // // Create vertical stripes of left/right
      // for (const q of [MIN_Q + irandom(2), MAX_Q - irandom(2)]) {
      //   for (let r = Math.max(MIN_S - q, MIN_R) + 1;
      //     r <= Math.min(MAX_S - q, MAX_R) - 1; ++ r) {
      //     Grid.set(q, r, new Cell());
      //   }
      // }
    },
    // hand: ['Dagger', 'Barrier', 'Barrier', 'Barrier', 'Barrier', 'Bridge', 'Bridge'],
    // hexes: 8,
    hand: ["Dagger"],
    hexes: 2,
  },
  minefield: {
    generate: () => {
      // Create a bit of chaos
      Grid.filter((x) => x.glossaryType === "air").forEach((x) =>
        x.set("earth")
      );

      const leftEmpty = choose(["air", "water"]);
      const rightEmpty = choose(["air", "water"]);

      // Create meandering paths
      const deck = Game.opponent;
      for (let cell of Grid.filter((x) => x.r === MIN_R)) {
        for (let i = 12; i--; ) {
          const next = choose(
            (Math.random() < 0.35
              ? [cell.below]
              : [
                  cell.below,
                  // cell.lowerLeft,
                  // cell.lowerRight,
                  cell.q === -1
                    ? cell.lowerLeft
                    : cell.q === 1
                    ? cell.lowerRight
                    : cell.q < 0
                    ? cell.lowerRight
                    : cell.lowerLeft,
                ]
            ).filter((x) => !x?.type)
          );
          if (!next) break;
          cell = next;
          if (cell.adjacentCells.some((x) => x.element === "castle")) {
            continue;
          } else if (
            Math.random() <
            0.8 /* cell.adjacentCells.some(x => x.type === 'Mine') */
          ) {
            if (!cell.type) {
              if (Math.random() < 0.1) {
                cell.set("Barrier", deck);
              } else {
                cell.set(cell.q < 0 ? leftEmpty : rightEmpty);
              }
            }
          } else {
            cell.set("Mine", deck);
          }
        }
      }
    },
    onTurn: async () => {
      const places = shuffle(
        Grid.filter(
          (x) =>
            x.glossaryType === "earth" &&
            !x.adjacentCells.some((y) => y.element === "castle")
        )
      ).slice(0, 3);
      for (const cell of places) {
        cell.set("Mine", Game.opponent);
        cell.animateDestroy();
        await sleep(500);
      }
    },
    hand: [
      "Barrier",
      "Barrier",
      "Barrier",
      "Bridge",
      "Bridge",
      "Anchor",
      "Anchor",
    ],
    hexes: 9,
  },
  field: {
    generate: () => {
      const limits = {
        Treasure: 2,
        Shield: 8,
        Sword: 4,
        Mine: Infinity,
        Barrier: Infinity,
      };
      // Fill in a grid everywhere except next to the player castle
      Grid.forEach((cell) => {
        if (cell.type) return;
        const castle = cell.adjacentCells.find((x) => x.element === "castle");
        if (castle) {
          if (castle.isPlayer) {
            cell.set("Shield", Game.player);
          } else {
            cell.set("earth");
          }
          return;
        }
        // Leave a moat around the player
        if (
          (cell.q > 0 && cell.r === MAX_R - 2) ||
          (cell.q <= 0 && cell.s === MIN_S + 2)
        ) {
          cell.set("earth");
          return;
        }
        // Push everything down when there's an opening (thx horses!)
        if (
          (cell.q < 0 && cell.r === MIN_R + 2) ||
          (cell.q >= 0 && cell.s === MAX_S - 2)
        ) {
          cell.set("Horse", Game.opponent);
          return;
        }
        // Skip the left/right edges
        if (Math.abs(cell.q) === MAX_Q) {
          cell.set("earth");
          return;
        }
        // Fill in the rest with earth, treasure, or stuff to mine through
        if (Math.random() < 0.2) {
          cell.set("earth");
          return;
        }
        if (Math.random() < 0.05 && limits.Treasure > 0) {
          cell.set("Treasure");
          limits.Treasure--;
          return;
        }
        let type = choose([
          ...repeat("Barrier", 8),
          ...repeat("Mine", 5),
          ...repeat("Shield", 3),
          "Sword",
        ]);
        if (limits[type] > 0) {
          limits[type]--;
        } else {
          type = "Barrier";
        }
        cell.set(type, Game.opponent);
      });
    },
    hand: [
      "Sword",
      ...repeat("Shield", 2),
      ...repeat("Mine", 2),
      ...repeat("Dagger", 2),
    ],
    hexes: 9,
  },
  swim: {
    generate: () => {
      // Fill in all of the air with water
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        cell.set("water");
      }

      // Create central horizontal stripe
      const deck = Game.opponent;
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        Grid.set(q, 0, new Cell("water"));
        let cell;
        if (q === MIN_Q) {
          cell = new Cell("Sword", { deck });
        } else {
          cell = new Cell();
        }
        Grid.set(q, -2, cell);
      }
    },
    hand: ["Anchor", "Sailboat", "Sailboat", "Barrier", "Barrier"],
    // hexes: 6,
  },
  vault: {
    generate: () => {
      // Fill in all of the air with water
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        cell.set("water");
      }

      // Create central vertical stripe
      for (let r = MIN_R + 1; r <= MAX_R - 1; ++r) {
        Grid.set(0, r, new Cell());
      }

      // Create two gates
      const deck = Game.opponent;
      Grid.set(0, -3, new Cell("Mine", { deck }));
      Grid.set(-1, -2, new Cell("Mine", { deck }));
      Grid.set(1, -3, new Cell("Mine", { deck }));
      Grid.set(0, -2, new Cell("Shield", { deck }));
      Grid.set(-1, -1, new Cell("Mine", { deck }));
      Grid.set(1, -2, new Cell("Mine", { deck }));
      Grid.set(0, -1, new Cell("Shield", { deck }));

      Grid.set(0, 1, new Cell("Mine", { deck }));
      Grid.set(0, 2, new Cell("Shield", { deck }));
    },
    onTurn: async () => {
      for (const cell of Game.opponent.placed) {
        if (cell.type !== "Barrier") continue;
        cell.setType("Shield");
        cell.animateDestroy();
        await sleep(500);
      }
    },
    hand: ["Barrier", "Sword", "Sword", "Barrier", "Barrier"],
    // hexes: 6,
  },
  fish: {
    generate: () => {
      // Fill in all of the air with water
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        cell.set("water");
      }

      // Create dock
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        if (!q) continue;
        const r = MAX_R - Math.max(0, q);
        Grid.set(q, r, new Cell("Barrier", { deck: Game.player }));
      }

      // Create train
      Grid.set(0, MIN_R + 1, new Cell("Train"));

      // Fill in a couple random piece of the water with treasure
      for (const q of [-2, 2]) {
        const offset = irange(-1, 1) - Math.max(0, q);
        Grid.set(
          q,
          offset,
          new Cell(choose(UNLOCKABLE_TYPES), { deck: Game.opponent })
        );
      }
    },
    hand: ["FishingRod"],
    // hexes: 6,
  },
  dig: {
    generate: () => {
      // Fill in all of the air with random earthy things
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        let type = "earth";
        if (Math.abs(cell.r) < MAX_R - 2) {
          type = choose("earth", "Barrier", "Barrier");
        }
        cell.set(type, type === "earth" ? null : Game.opponent);
      }

      // Fill in some places with shields
      const shields = new Map();
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        const offset = irange(-2, 2);
        const shield = new Cell("Shield", { deck: Game.opponent });
        shields.set(q, shield);
        Grid.set(q, offset, shield);
      }

      // Replace a couple of random shields with treasure
      for (const q of [irange(MIN_Q + 1, -1), irange(1, MAX_Q - 1)]) {
        shields.get(q).set("Treasure", null);
      }
    },
    hand: [
      "Shovel",
      "Sword",
      "Sword",
      "Barrier",
      "Barrier",
      "Barrier",
      "Barrier",
    ],
    // hexes: 6,
  },
  fly: {
    generate: () => {
      // Create a line of barriers
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        Grid.set(q, 0, new Cell("Barrier", { deck: Game.opponent }));
      }
    },
    hand: ["Arrow", "Dove", "Dove", "Bridge", "Bridge", "Barrier", "Barrier"],
    // hexes: 6,
  },
  slide: {
    generate: () => {
      // Fill in all of the air with water
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        if (Math.abs(cell.q) !== 2) {
          if (Math.abs(Math.abs(cell.q) - 2) === 1) {
            cell.set("earth");
          }
          continue;
        }
        cell.set("water");
      }

      // Create docks
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        if (!q) continue;
        Grid.set(
          q,
          MAX_R - Math.max(0, q),
          new Cell("Barrier", { deck: Game.player })
        );
        if (Math.abs(q) === 1) continue;
        Grid.set(
          q,
          MIN_R + Math.max(0, -q),
          new Cell("Barrier", { deck: Game.opponent })
        );
      }

      // Fill in a couple pieces of land with treasure
      for (const q of [-1, 1]) {
        Grid.set(
          q,
          -3 - Math.max(0, q),
          new Cell(
            choose(
              UNLOCKABLE_TYPES.filter(
                (x) => (GLOSSARY[x].element ?? "earth") === "earth"
              )
            ),
            { deck: Game.opponent }
          )
        );
        // Create shields to block direct access to the treasure
        Grid.set(
          q * 3,
          -Math.max(0, q * 3),
          new Cell("Shield", { deck: Game.opponent })
        );
        Grid.set(
          q,
          -1 - Math.max(0, q),
          new Cell("Shield", { deck: Game.opponent })
        );
      }
    },
    hand: ["Barrier", "Anchor", "Hand", "Barrier", "Barrier"],
    // hexes: 6,
  },
  web: {
    generate: () => {
      // Create a line of barriers
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        let cell;
        if (Math.abs(q) === 2) {
          cell = new Cell("Treasure");
        } else {
          cell = new Cell("Barrier", { deck: Game.opponent });
        }
        Grid.set(q, 0, cell);
      }
      // Fill in the rest with earth
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        cell.set("earth");
      }
    },
    hand: ["Spider", ...repeat("Barrier", 6)],
    hexes: 9,
  },
  loop: {
    generate: () => {
      // Create a line of barriers
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        let cell;
        if (Math.abs(q) === 2) {
          cell = new Cell("Treasure");
        } else {
          cell = new Cell("Barrier", { deck: Game.opponent });
        }
        Grid.set(q, 0, cell);
      }
      // Fill in the rest with earth
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        cell.set("earth");
      }
    },
    hand: [
      "Repeater",
      ...repeat("Barrier", 2),
      "Horse",
      "Sword",
      ...repeat("Barrier", 2),
    ],
    hexes: 9,
  },
  extend: {
    generate: () => {
      // Create a line of barriers
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        let cell;
        if (Math.abs(q) === 2) {
          cell = new Cell("Treasure");
        } else {
          cell = new Cell("Barrier", { deck: Game.opponent });
        }
        Grid.set(q, -1, cell);
      }
      // Fill in the rest with earth
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        cell.set("earth");
      }
    },
    // onTurn: async () => {
    //   let anyInvalid = false;
    //   for (const cell of Game.player.placed) {
    //     if (cell.type !== "Sword") continue;
    //     if (cell.forwards?.type) continue;
    //     anyInvalid = true;
    //     break;
    //   }
    //   if (!anyInvalid) return;
    //   for (let q = MIN_Q; q <= MAX_Q; ++q) {
    //     const existing = Grid.get(q, -1);
    //     if (existing.type) continue;
    //     existing.set("Sword", Game.opponent);
    //     existing.animateDestroy();
    //     await sleep(500);
    //   }
    //   await Game.opponent.doTurn();
    // },
    hand: [
      "Sword",
      ...repeat("Barrier", 2),
      ...repeat("Extender", 2),
      ...repeat("Barrier", 2),
    ],
    hexes: 9,
  },
  split: {
    generate: () => {
      // Create a split path (props to Robert Frost)

      // Moat
      for (let q = -2; q <= 2; ++q) {
        if (!q) continue;
        const offset = Math.abs(q) === 2 ? -3 : -4;
        Grid.set(q, offset - Math.max(0, q), new Cell("earth"));
      }

      // Treasure
      for (const q of [-2, 2]) {
        Grid.set(q, -2 - Math.max(0, q), new Cell("Treasure"));
      }

      // Split paths
      for (let r = -1; r < 0; ++r) {
        Grid.set(-2, r, new Cell("earth"));
        Grid.set(2, r - 2, new Cell("earth"));
      }

      // Transition
      for (let q = -2; q <= 2; ++q) {
        Grid.set(q, -Math.max(0, q), new Cell("earth"));
      }

      // Single path
      for (let r = 1; r <= 3; ++r) {
        Grid.set(0, r, new Cell("earth"));
      }
      Grid.set(0, 4, new Cell("Shield", { deck: Game.player }));
    },
    hand: [
      "Sword",
      ...repeat("Barrier", 2),
      ...repeat("Extender", 2),
      ...repeat("Barrier", 2),
    ],
    hexes: 9,
  },
};

export const CURIOSITY_KEYS = Object.freeze(Object.keys(CURIOSITIES));
