import {
  CELL,
  CELL_BORDER,
  CELL_SHADOW_OFFSET,
  COLOR,
  FONT,
} from "./constants";
import { Game } from "./game";
import { PATTERNS } from "./pattern";
import { clamp } from "./util";

const Layers = new (class {
  constructor() {
    // Layers in the order they should be rendered to the canvas
    this.ordered = [];
    // .idString reference for every layer
    this.reference = {};
    // Keeps track of unique types that are currently being rendered
    this.types = new Set();
  }

  // Adds `value` to the layer with the given `type` and `id`, creating it
  // if necessary. (`removePrevious` indicates whether to remove this value
  // from other layers of the same type before adding it to this layer.)
  add(type, id, value, removePrevious = true) {
    if (type === "string") {
      throw new Error(`Layers.add expects a class for type, not a string.`);
    }
    if (removePrevious) {
      value?.$layers?.[type.type]?.remove(value);
    }
    const idString = this.getIdString(id);
    const key = `${type.type}:${idString}`;
    let layer = this.reference[key];
    if (!layer) {
      layer = this.reference[key] = new type(id, idString);
      layer.idString = idString;
      layer.key = key;
      this.types.add(layer.type);
      this.updateOrder();
    }
    layer.add(value);
    if (!value.$layers) {
      value.$layers = {};
    }
    value.$layers[type.type] = layer;
    return layer;
  }

  getIdString(id = {}) {
    if (!id || typeof id !== "object") {
      return id;
    }
    return Object.keys(id)
      .sort()
      .map((x) => `${x}=${id[x]}`)
      .join(":");
  }

  // Removes layers that don't have values
  prune() {
    this.types.clear();
    for (let i = this.ordered.length; i--; ) {
      const layer = this.ordered[i];
      if (layer.values.length) {
        this.types.add(layer.type);
        continue;
      }
      layer.values.splice(i, 1);
      delete this.reference[layer.key];
    }
  }

  // This removes the given value from any layer that contains it
  remove(value, type = null) {
    for (const layer of this.ordered) {
      if (type && layer instanceof type === false) {
        continue;
      }
      layer.remove(value);
    }
  }

  // Removes every single layer
  removeAll() {
    for (const layer of this.ordered) {
      for (const value of layer.values.slice()) {
        layer.remove(value);
      }
    }
    this.prune();
  }

  // Renders all layers in order
  render(ctx, timestamp) {
    for (const layer of this.ordered) {
      if (layer.values.length) {
        layer.render(ctx, timestamp);
      }
    }
  }

  // Updates layer order to ensure things are rendered properly
  updateOrder() {
    this.ordered = Object.values(this.reference).sort((a, b) => {
      if (a.priority !== b.priority) {
        return a.priority - b.priority;
      }
      // TODO
      return 0;
    });
  }
})();

export default Layers;

// Testing!
window.Layers = Layers;

// Abstract class extended for specific layers of related rendering operations
class Layer {
  constructor(id) {
    if (!this.constructor.type) {
      throw new Error(`${this.constructor.name}.type is not defined!`);
    }
    this.type = this.constructor.type;
    this.priority = 0;
    this.id = id;
    this.idString = "";
    this.key = "";
    this.values = [];
    this.prepare();
  }

  add(value) {
    if (this.values.includes(value)) {
      return;
    }
    this.values.push(value);
  }

  prepare() {
    // Overridden by classes that extend this
  }

  remove(value) {
    const index = this.values.indexOf(value);
    if (index === -1) {
      return;
    }
    this.values.splice(index, 1);
    if (value.$layers[this.type] === this) {
      value.$layers[this.type] = null;
    }
  }

  render(ctx, timestamp) {
    throw new Error(`${this.constructor.name}.render is not implemented!`);
  }
}

// Layer for logic that should happen before/after rendering all cells
export class CellProcessingLayer extends Layer {
  static get type() {
    return "CellProcessing";
  }

  prepare() {
    if (this.id === "before") {
      this.priority = 0;
    } else {
      this.priority = 100;
    }
  }

  render(ctx) {
    if (this.id === "before") {
      for (const cell of this.values) {
        cell.renderBefore(ctx);
      }
    } else {
      ctx.globalAlpha = 1;
      for (const cell of this.values) {
        cell.renderAfter(ctx);
      }
    }
  }
}

// Layer for a pseudo-3d shadow below a filled cell
export class CellShadowLayer extends Layer {
  static get type() {
    return "CellShadow";
  }

  prepare() {
    if (this.id.elevate) {
      this.priority = 23;
    } else if (this.id.held) {
      this.priority = 13;
    } else {
      this.priority = 3;
    }
    if (this.id.color[0] === "#") {
      this.y = CELL_SHADOW_OFFSET;
    } else {
      this.y = 0;
    }
  }

  render(ctx) {
    ctx.globalAlpha = 0.5;
    let dy = 0;
    if (this.id.hand && Game.handY) {
      dy = Game.handY;
      // The background of the cells still has to use translate or the
      // pattern will not look correct as they move
      ctx.translate(0, dy);
    }
    if (this.id.lineWidth) {
      ctx.beginPath();
      for (const cell of this.values) {
        cell.renderPath(ctx, CELL_BORDER, 0, this.y);
      }
      ctx.strokeStyle = this.id.color;
      ctx.lineWidth = this.id.lineWidth;
      ctx.lineJoin = "round";
      ctx.stroke();
    }
    // ctx.beginPath();
    // for (const cell of this.values) {
    //   cell.renderPath(ctx, 0, 0, this.y);
    // }
    // ctx.fillStyle = this.id.color;
    // ctx.fill();
    if (dy) {
      ctx.translate(0, -dy);
    }
    ctx.globalAlpha = 1;
  }
}

// Layer for the colorful & patterned backgrounds of cells
export class CellBackgroundLayer extends Layer {
  static get type() {
    return "CellBackground";
  }

  prepare() {
    if (this.id.held) {
      this.priority = 15;
    } else {
      this.priority = 5;
      if (this.id.color === COLOR.black) {
        this.priority = 4;
        this.pattern = null;
      } else if (this.id.color[0] === "#") {
        this.pattern = "polygons";
      } else {
        this.priority = 3;
        this.pattern = "lines";
      }
      if (this.id.elevate) {
        this.priority = 25;
      }
    }
    if (this.id.color[0] === "#") {
      this.y = 0;
    } else {
      this.y = CELL_SHADOW_OFFSET;
    }
  }

  render(ctx) {
    let dy = 0;
    if (this.id.hand && Game.handY) {
      dy = Game.handY;
      // The background of the cells still has to use translate or the
      // pattern will not look correct as they move
      ctx.translate(0, dy);
    }
    if (this.id.lineWidth) {
      ctx.beginPath();
      for (const cell of this.values) {
        cell.renderPath(ctx, cell.adjustedRadius + CELL_BORDER, 0, this.y);
      }
      ctx.strokeStyle = this.id.color;
      ctx.lineWidth = this.id.lineWidth;
      ctx.lineJoin = "round";
      ctx.stroke();
    }
    ctx.beginPath();
    for (const cell of this.values) {
      cell.renderPath(ctx, cell.adjustedRadius, 0, this.y);
    }
    ctx.fillStyle = this.id.color;
    ctx.fill();
    if (this.pattern) {
      ctx.fillStyle = PATTERNS[this.pattern];
      // if (this.id.lineWidth) {
      //   ctx.strokeStyle = ctx.fillStyle;
      //   ctx.stroke();
      // }
      ctx.fill();
    }
    if (dy) {
      ctx.translate(0, -dy);
    }
  }
}

// Layer for just the borders of cells
export class CellBorderLayer extends Layer {
  static get type() {
    return "CellBorder";
  }

  prepare() {
    if (this.id.held) {
      this.priority = 16;
    } else if (this.id.filled) {
      this.priority = 6;
    } else {
      this.priority = 4;
    }
    if (this.id.filled) {
      this.y = 0;
    } else {
      this.y = CELL_SHADOW_OFFSET;
    }
  }

  render(ctx) {
    let dy = 0;
    if (this.id.hand && Game.handY) {
      dy = Game.handY;
    }
    ctx.beginPath();
    for (const cell of this.values) {
      cell.renderPath(ctx, 0, 0, this.y + dy);
    }
    ctx.strokeStyle = Game.background;
    ctx.lineWidth = this.id.width;
    ctx.lineJoin = "round";
    ctx.stroke();
  }
}

// Layer for cell symbols
export class CellSymbolLayer extends Layer {
  static get type() {
    return "CellSymbol";
  }

  prepare() {
    if (this.id.elevate) {
      this.priority = 26;
    } else if (this.id.held) {
      this.priority = 16;
    } else {
      this.priority = 6;
    }
  }

  render(ctx) {
    let dy = 0;
    if (this.id.hand && Game.handY) {
      dy = Game.handY;
    }
    ctx.font = FONT.CELL_SYMBOL;
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    if (this.id.color) {
      ctx.fillStyle = this.id.color;
    } else {
      // ctx.fillStyle = Game.background;
      ctx.fillStyle = "black";
    }
    for (const cell of this.values) {
      let symbol;
      if (cell.isRecovering) {
        symbol = "\uf720";
      } else if ((!cell.type || cell.type === "River") && cell.isValidPlace) {
        symbol = "+";
      } else {
        symbol = cell.symbol;
      }
      ctx.fillText(symbol, cell.x, cell.y + dy);
    }
  }
}

// Layer for showing the cost of cells in-hand
export class CellCostLayer extends Layer {
  static get type() {
    return "CellCost";
  }

  prepare() {
    this.priority = 10;
  }

  render(ctx) {
    ctx.fillStyle = COLOR.hand;
    ctx.textBaseline = "bottom";
    ctx.textAlign = "right";
    ctx.font = FONT.CELL_COST_ICON(13);
    let hovered, selected;
    for (const cell of this.values) {
      if (cell.isHovered) {
        hovered = cell;
        continue;
      }
      if (cell.isSelected) {
        selected = cell;
        continue;
      }
      this.renderIcon(ctx, cell);
    }
    ctx.textAlign = "left";
    ctx.font = FONT.CELL_COST_TEXT(15);
    for (const cell of this.values) {
      if (cell === hovered || cell === selected) {
        continue;
      }
      this.renderText(ctx, cell);
    }
    for (const cell of [hovered, selected]) {
      if (!cell) continue;
      ctx.fillStyle = COLOR.player;
      ctx.textAlign = "right";
      ctx.font = FONT.CELL_COST_ICON(16);
      this.renderIcon(ctx, cell);
      ctx.textAlign = "left";
      ctx.font = FONT.CELL_COST_TEXT(20);
      this.renderText(ctx, cell);
    }
  }

  renderIcon(ctx, cell) {
    ctx.fillText(
      "\uf312",
      cell.x - 2,
      cell.y - CELL.SIZE - CELL.FLUX + Game.handY
    );
  }

  renderText(ctx, cell) {
    ctx.fillText(
      cell.cost,
      cell.x + 2,
      cell.y + 1 - CELL.SIZE - CELL.FLUX + Game.handY
    );
  }
}

// Layer for cell fade in/out transitions
export class CellFadeLayer extends Layer {
  static get type() {
    return "CellFade";
  }

  prepare() {
    this.priority = 20;
    if (this.id.filled) {
      this.y = 0;
    } else {
      this.y = CELL_SHADOW_OFFSET;
    }
  }

  render(ctx, timestamp) {
    let isDone = false,
      t,
      border;
    if (this.id.loop) {
      t = timestamp / 1000;
      border = -0.5 * CELL_BORDER;
    } else if (this.id.active) {
      const delta = clamp(0, (timestamp - this.id.t) / 250, 1);
      t = 0.5 + Math.abs(0.5 - delta);
      isDone = delta === 1;
    } else {
      t = clamp(0, (timestamp - this.id.t) / 500, 1);
      border = -0.6 * CELL_BORDER;
      isDone = t === 1;
    }
    const lerp = Math.sin(t * Math.PI * 0.5);
    if (this.id.active) {
      ctx.globalAlpha = (1 - lerp) * 0.6;
      ctx.globalCompositeOperation = "xor";
    } else if (this.id.loop) {
      ctx.globalAlpha = 0.2 * (1 + lerp);
    } else if (this.id.in) {
      ctx.globalAlpha = 1 - lerp;
    } else {
      ctx.globalAlpha = lerp;
    }
    ctx.beginPath();
    for (const cell of this.values) {
      cell.renderPath(ctx, border, 0, this.y + (cell.inHand ? Game.handY : 0));
    }
    if (this.id.loop) {
      ctx.fillStyle = Game.background;
    } else if (this.id.c) {
      ctx.fillStyle = this.id.c;
    } else {
      ctx.fillStyle = Game.background;
    }
    ctx.fill();
    ctx.globalAlpha = 1;
    if (this.id.active) {
      ctx.globalCompositeOperation = "source-over";
    }
    if (isDone) {
      if (this.id.in) {
        for (const cell of this.values.slice()) {
          cell.addedTimestamp = 0;
          this.remove(cell);
          cell.needsLayerUpdate = true;
        }
        Layers.prune();
      } else if (this.id.active) {
        for (const cell of this.values.slice()) {
          cell.activeTimestamp = 0;
          this.remove(cell);
          cell.needsLayerUpdate = true;
        }
        Layers.prune();
      } else {
        for (const cell of this.values) {
          cell.hide();
        }
      }
    }
  }
}

// Layer for CellWall particles
export class CellWallLayer extends Layer {
  static get type() {
    return "CellWall";
  }

  prepare() {
    this.priority = 30;
  }

  render(ctx, timestamp) {
    const t = (timestamp - this.id.t) / CELL.DESTROY_DURATION;
    if (t >= 1) {
      for (const wall of this.values.slice()) {
        this.remove(wall);
      }
      Layers.prune();
      return;
    }
    const lerp = Math.sin(t * Math.PI * 0.5);
    ctx.lineCap = "round";
    ctx.lineWidth = 8 * (1 - lerp);
    ctx.globalAlpha = 1 - lerp * 0.5;
    ctx.beginPath();
    for (const wall of this.values) {
      wall.renderPath(ctx, lerp);
    }
    ctx.strokeStyle = this.id.c;
    ctx.stroke();
    ctx.globalAlpha = 1;
  }
}
