export function irandom(n) {
  return Math.floor(n * Math.random());
}

export function irange(min, max) {
  return min + irandom(1 + max - min);
}

export function range(min, max) {
  return min + (max - min) * Math.random();
}

export function clamp(min, n, max) {
  return Math.max(min, Math.min(n, max));
}

export function choose(array) {
  return array[irandom(array.length)];
}

const sleepSet = new Set();

export function sleep(n) {
  let ref;
  return new Promise((resolve, reject) => {
    sleepSet.add(
      (ref = {
        resolve,
        reject,
        timeout: setTimeout(resolve, n),
        timestamp: Date.now() + n,
        remaining: n,
      })
    );
  }).finally(() => sleepSet.delete(ref));
}

// Pause every single pending sleep Promise
export function snooze() {
  const now = Date.now();
  for (const ref of sleepSet) {
    if (!ref.timeout) {
      continue;
    }
    clearTimeout(ref.timeout);
    ref.timeout = 0;
    ref.remaining = ref.timestamp - now;
  }
}

// Resume every paused sleep Promise
export function niceAwakening() {
  const now = Date.now();
  for (const ref of sleepSet) {
    if (ref.timeout) {
      continue;
    }
    ref.timeout = setTimeout(ref.resolve, ref.remaining);
    ref.timestamp = now + ref.remaining;
    ref.remaining = 0;
  }
}

// Reject every single pending sleep Promise
export function rudeAwakening() {
  for (const { reject } of sleepSet) {
    reject();
  }
}

export function randomSleep(n, variation = Math.min(n * 0.5, 100)) {
  return sleep(n + irange(-variation, variation));
}

export function repeat(x, n) {
  return new Array(n).fill(x);
}

export function shuffle(array) {
  for (let i = 10; i--; ) {
    array.sort(() => (Math.random() < 0.5 ? -1 : 1));
  }
  return array;
}

export function allIn(expecting, ...values) {
  return values.every((x) => expecting.includes(x));
}
