import { CELL_SIZE } from "./constants";
import { irange } from "./util";

const FORMULAS = {
  dots: (canvas, ctx) => {
    const size = CELL_SIZE * 9;
    canvas.width = size;
    canvas.height = size;
    const l = size * 0.5;
    const interval = l * 0.2;
    // const angle = 0.1;
    ctx.fillStyle = 'rgba(0,0,0,0.15)';
    ctx.translate(l, l);
    // ctx.rotate(angle);
    for (let y = interval * 0.5, i; y < l + interval; y += interval, i = !i) {
      for (let x = 0, j; x < l + interval; x += interval, j = !j) {
        const r = irange(10, 32) * 0.01 * interval;
        // const r = 0.2 * interval;
        // ctx.fillStyle = `rgba(0,0,0,${irange(5, 16) * 0.01})`;
        const cy = y - (j ? interval * 0.5 : 0);
        ctx.beginPath();
        ctx.arc(x, cy, r, 0, Math.PI * 2);
        ctx.fill();
        if (x) {
          ctx.beginPath();
          ctx.arc(-x, cy, r, 0, Math.PI * 2);
          ctx.fill();
        }
        if (cy) {
          ctx.beginPath();
          ctx.arc(x, -cy, r, 0, Math.PI * 2);
          ctx.fill();
        }
        if (x && cy) {
          ctx.beginPath();
          ctx.arc(-x, -cy, r, 0, Math.PI * 2);
          ctx.fill();
        }
      }
    }
    // ctx.rotate(-angle);
    ctx.translate(-l, -l);
  },
  polygons: (canvas, ctx) => {
    const size = CELL_SIZE * 9;
    canvas.width = size;
    canvas.height = size;
    const l = size * 0.5;
    const interval = l * 0.2;
    ctx.fillStyle = 'rgba(0,0,0,0.08)';
    ctx.translate(l, l);

    const renderPolygon = (x, y, r, sides, angleStart) => {
      ctx.beginPath();
      if (sides < 3) {
        ctx.arc(x, y, r, 0, Math.PI * 2);
      } else {
        let first = true;
        const angleInc = Math.PI * 2 / sides;
        for (let i = sides; i --; ) {
          const a = angleStart + angleInc * i;
          ctx[first ? 'moveTo' : 'lineTo'](x + r * Math.cos(a), y + r * Math.sin(a));
          first = false;
        }
      }
      ctx.fill();
    };

    for (let y = interval * 0.5, i; y < l + interval; y += interval, i = !i) {
      for (let x = 0, j; x < l + interval; x += interval, j = !j) {
        const r = irange(16, 32) * 0.01 * interval;
        // const sides = irange(2, 6);
        const sides = irange(3, 6);
        const angleStart = 2 * Math.PI * Math.random();
        const cy = y - (j ? interval * 0.5 : 0);
        ctx.beginPath();
        renderPolygon(x, cy, r, sides, angleStart);
        ctx.fill();
        if (x) {
          ctx.beginPath();
          renderPolygon(-x, cy, r, sides, angleStart);
          ctx.fill();
        }
        if (cy) {
          ctx.beginPath();
          renderPolygon(x, -cy, r, sides, angleStart);
          ctx.fill();
        }
        if (x && cy) {
          ctx.beginPath();
          renderPolygon(-x, -cy, r, sides, angleStart);
          ctx.fill();
        }
      }
    }
    ctx.translate(-l, -l);
  },
  lines: (canvas, ctx) => {
    const size = CELL_SIZE * 4;
    canvas.width = size;
    canvas.height = size;
    ctx.strokeStyle = 'rgba(0,0,0,0.05)';
    const interval = size * 0.2;
    ctx.lineWidth = interval * 0.75;
    ctx.lineCap = 'round';
    for (let y = -interval; y < size + interval; y += interval) {
      ctx.moveTo(0, y);
      ctx.lineTo(size, y + interval);
    }
    ctx.stroke();
  },
  waves: (canvas, ctx) => {
    const line = 2;
    const underbite = 0; // line * 0.5;
    const size = CELL_SIZE + underbite * 2;
    // const radius = size - line / 2;

    canvas.width = size - underbite * 2;
    canvas.height = size - underbite * 2;

    ctx.translate(-underbite, -underbite);

    ctx.strokeStyle = 'rgba(0,0,0,0.1)';
    ctx.lineWidth = line;
    ctx.lineCap = 'round';
    ctx.beginPath();
    // ctx.arc(0, 0, radius, 0, Math.PI * 2);
    // ctx.arc(size * 0.3, size * 0.3, radius, 0, Math.PI * 2);
    ctx.moveTo(size, 0);
    ctx.bezierCurveTo(size * 0.6, size * 0.7, size * 0.3, size * 0.4, 0, size);
    ctx.stroke();

    ctx.translate(underbite, underbite);
  },
};

export const CANVASES = {};
export const PATTERNS = {};
export const IMAGES = {};

for (const key in FORMULAS) {
  PATTERNS[key] = 'transparent';
  const canvas = CANVASES[key] = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  FORMULAS[key](canvas, ctx);
  IMAGES[key] = `url(${canvas.toDataURL()})`;
}

// Update actual pattern objects using destination context
export function updatePatterns(ctx) {
  for (const pattern in PATTERNS) {
    PATTERNS[pattern] = ctx.createPattern(CANVASES[pattern], 'repeat');
  }
}