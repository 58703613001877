import { HAND_SIZE, HAND_SPECIAL_SIZE, MAX_TYPE_STACK } from "./constants";
import { Game } from "./game";
import { GLOSSARY } from "./glossary";
import Save from "./save";
import { choose, clamp, irange, repeat, shuffle, sleep } from "./util";

export class Deck {
  constructor(options = {}) {
    this.name = options.name || "";
    this.isInTurn = false;
    this.cells = options.cells || []; // Not drawn yet this match
    this.starting = this.cells.slice();
    this.drawn = []; // Currently in hand
    this.discarded = []; // Drawn earlier this match, but unused
    this.placed = []; // Cells currently placed on the grid from this deck
    this.hexes = 0;
    this.shuffle();
    this.ai = options.ai || {
      // Offsets likelihood of choosing the best vibes
      intellect: -10,
      // How much intellect varies by randomly
      luck: 5,
    };
    //
    // Callbacks
    //
    this.setBackground = options.setBackground || null;
  }

  get json() {
    return {
      name: this.name,
      isInTurn: this.isInTurn,
      cells: this.cells,
      starting: this.starting,
      drawn: this.drawn,
      discarded: this.discarded,
      placed: this.placed.map((x) => x.id),
      hexes: this.hexes,
      ai: this.ai,
    };
  }
  set json(json) {
    if (!json) return;
    for (const [prop, value] of Object.entries(json)) {
      if (prop === "placed") {
        this.placed = value.map((x) => Save.cells[x]);
      } else {
        this[prop] = value;
      }
    }
  }

  get enemy() {
    if (Game.player === this) {
      return Game.opponent;
    }
    return Game.player;
  }

  get isBoss() {
    return this === Game.opponent && Game.isBoss;
  }

  static generate(options = {}) {
    let cells, ai;
    if (options.basedOn) {
      const focusTypes = options.focusTypes ?? window.Game?.focusTypes ?? [];
      if (options.isBoss) {
        cells = Array.from(new Set(["Dagger", "Sword", ...focusTypes])).flatMap(
          (x) => repeat(x, 3)
        );
      } else {
        const typesWithoutDagger = new Set(options.basedOn.starting);
        typesWithoutDagger.delete("Dagger");
        const types = Array.from(typesWithoutDagger);
        const focusType = focusTypes[0];
        const daggerCount = 2;
        const length = options.basedOn.starting.length + MAX_TYPE_STACK;
        cells = new Array(length).fill().map((_, i) => {
          if (i < daggerCount) {
            return "Dagger";
          } else if (focusType && i < daggerCount + MAX_TYPE_STACK) {
            return focusType;
          } else {
            return choose(types);
          }
        });
      }
    } else {
      cells = [
        repeat("Sword", 2),
        // repeat('Horse', 2),
        // repeat('Mine', 2),
        // repeat('River', 1),
        repeat("Dagger", 3),
        // repeat('PairOfSwords', 2),
        // repeat("Dove", 20),
        // repeat('Bridge', 20),
        // repeat("Campfire", 20),
        // repeat('RainCloud', 2),
        // repeat('Anchor', 2),
        // repeat('Sailboat', 2),
        // repeat('Acorn', 20),
        // repeat("Arrow", 20),
        // repeat("Frog", 20),
        // repeat("Battery", 20),
        // repeat("Train", 20),
        // repeat("Shovel", 20),
        // repeat("FishingRod", 20),
        // repeat("Hand", 20),
        // repeat("Parry", 20),
        // repeat("Eraser", 20),
        // repeat("Scythe", 20),
        // repeat("Extender", 20),
        // repeat("Repeater", 20),
        // repeat("Rocket", 20),
        // repeat("Nuke", 20),
        // repeat("Medic", 20),
        // repeat("Extinguisher", 20),
        // repeat("Crosshairs", 20),
        // repeat("Buzzsaw", 20),
        // repeat("Axe", 20),
        // repeat("Crab", 20),
        // repeat("Bomb", 20),
        // repeat("Spider", 20),
      ].flat();
    }
    if (options.previous) {
      const level = options.level || 1;
      // Intellect raises by one each level (clamped to a max of 10)
      const intellect = level - 11;
      // Luck circles from being great (+20) to terrible (-20) as the
      // AI approaches maximum intellect (10) and then to neutral (0)
      const luck = 20 * Math.cos(Math.PI * Math.min(level / 20, 2));
      ai = {
        intellect: clamp(
          AiLimits.intellect.min,
          intellect,
          AiLimits.intellect.max
        ),
        luck: clamp(AiLimits.luck.min, luck, AiLimits.luck.max),
      };
    }
    return new this({
      cells,
      ai,
      ...options,
    });
  }

  // Adds a cell to the starting state of this deck
  add(type) {
    if (typeof type !== "string") {
      type = type.type;
    }
    const glossaryInfo = GLOSSARY[type];
    if (!glossaryInfo || glossaryInfo.storable === false) {
      return;
    }
    this.starting.push(type);
  }

  // Adds a cell to the discard pile of this deck
  discard(cell) {
    const glossaryInfo = GLOSSARY[cell.type];
    if (!glossaryInfo || glossaryInfo.storable === false) {
      return;
    }
    this.discarded.push(cell.type);
  }

  // Delete a cell from this deck permanently
  delete(cell) {
    const index = this.starting.indexOf(cell.type);
    if (index === -1) return;
    this.starting.splice(index, 1);
  }

  // Deletes excessive counts of cells
  prune() {
    const counts = {};
    for (const type of this.starting) {
      if (type in counts) {
        const newCount = ++counts[type];
        const max = GLOSSARY[type]?.limit ?? MAX_TYPE_STACK;
        if (newCount >= max) {
          counts[type] = max;
        }
      } else {
        counts[type] = 1;
      }
    }
    this.starting = Object.entries(counts).flatMap(([type, count]) =>
      repeat(type, count)
    );
  }

  draw(elements = new Set(["earth", "water", "air"])) {
    const specials = HAND_SPECIAL_SIZE + irange(-1, 1);
    for (let attempts = 50; attempts--; ) {
      const newCells = this.cells
        .splice(0, specials - this.drawn.length)
        .filter((type) => {
          const element = GLOSSARY[type]?.element ?? "earth";
          if (elements.has(element)) return true;
          this.discarded.push(type);
          return false;
        });
      this.drawn.push(...newCells);
      if (this.drawn.length >= specials) {
        break;
      }
      if (!this.cells.length) {
        if (!this.discarded.length) {
          break;
        }
        this.cells.push(...this.discarded.splice(0));
        this.shuffle();
      }
    }
    this.drawn.push(...repeat("Barrier", HAND_SIZE - this.drawn.length));
    shuffle(this.drawn);
  }

  async doTurn({ cells = this.placed, includeEnemy = false } = {}) {
    // Run through onTurn callbacks
    const placedBefore = new Set(this.placed);
    const groups = new Map();
    for (const cell of cells) {
      if (!cell.onTurn) {
        continue;
      }
      const priority =
        cell.priority + (cell.glossary?.parallel === false ? Math.random() : 0);
      let group = groups.get(priority);
      if (!group) {
        groups.set(priority, (group = []));
      }
      group.push({ cell, type: cell.type, isEnemy: false });
    }
    if (includeEnemy) {
      for (const cell of this.enemy.placed) {
        if (!cell.onEnemyTurn) {
          continue;
        }
        let group = groups.get(cell.priority);
        if (!group) {
          groups.set(cell.priority, (group = []));
        }
        group.push({ cell, type: cell.type, isEnemy: true });
      }
    }
    const sort = (cells) => {
      const sorted = cells.sort((a, b) => a.cell.y - b.cell.y);
      if (this.name === "Player") {
        sorted.reverse();
      }
      return sorted;
    };
    for (const priority of [...groups.keys()].sort((a, b) => b - a)) {
      await Promise.all(
        sort(groups.get(priority)).map(async (info) => {
          const { cell, type, isEnemy } = info;
          if (cell.type !== type) {
            return;
          }
          await cell.animateActive();
          if (!isEnemy) {
            await cell.onTurn?.();
          } else {
            await cell.onEnemyTurn?.();
          }
        })
      );
      await sleep(500);
      const newCells = [];
      for (const cell of this.placed) {
        if (placedBefore.has(cell)) continue;
        newCells.push(cell);
        placedBefore.add(cell);
      }
      if (newCells.length) {
        await this.doTurn({
          cells: newCells,
        });
      }
    }
  }

  async endTurn() {
    // Clear undo state
    Game.setCanUndo?.(false);
    // Discard drawn cells that weren't played
    this.discarded.push(
      ...this.drawn
        .splice(0)
        .filter((type) => GLOSSARY[type]?.storable !== false)
    );
    await this.doTurn({
      includeEnemy: true,
    });
    this.isInTurn = false;
    this.hexes = 0;
  }

  play(cell, index) {
    this.hexes -= cell.cost;
    this.drawn.splice(index, 1);
    this.place(cell);
  }

  place(cell) {
    if (cell.inHand) return;
    if (!this.placed.includes(cell)) {
      this.placed.push(cell);
    }
  }

  unplace(cell) {
    if (cell.inHand) return;
    const index = this.placed.indexOf(cell);
    if (index === -1) return;
    this.placed.splice(index, 1);
  }

  reset() {
    this.prune();
    this.cells = this.starting.slice();
    this.shuffle();
    this.discarded.splice(0);
  }

  shuffle() {
    shuffle(this.cells);
  }

  startTurn(elements) {
    const { curiosity } = Game;
    if (curiosity?.hand) {
      if (typeof curiosity.hand === "function") {
        this.drawn = curiosity.hand();
      } else {
        this.drawn = [...curiosity.hand];
      }
    } else {
      this.draw(elements);
    }
    this.isInTurn = true;
    this.hexes = curiosity?.hexes ?? 6;
    if (this.isBoss) this.hexes = 9;
    for (const cell of this.placed) {
      this.hexes += cell.glossary?.bonusCells ?? 0;
    }
    this.setBackground?.();
  }
}

const AiLimits = {
  intellect: {
    min: -10,
    max: 10,
  },
  luck: {
    min: -20,
    max: 20,
  },
};
