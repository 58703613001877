import { COLOR } from "./constants";
import Sound from "./sound";
import { choose, randomSleep, sleep } from "./util";

// Bow and arrow. Bow sticks around and shoots slow-moving arrows (horse-speed)
// Wizard staff. (Cleric that makes shields)

// Bridge. Doesn't move or anything fancy, but is placed in the air, and then
// becomes a regular earth tile.

// Acorn. Low cost, but during the enemy's turn (within priority) it becomes a tree.
// Tree. Can't be directly created/stored, and is flammable, but turns into a Barrier
// when damaged normally.

// Could be multiple types of mages/wizards that do an action on the turn that
// they're placed, but then destroy themselves. (Could cost 4+ so they can't be
// doubled up.)

// Could have some sort of tile that grants extra hexes on future turns for as
// long as it is kept intact.

// Level with new type (either enemy has it or open area with it)

// Game over show stats (number of kills per type of tile)
// If you kill (x) amount of a type of tile, you could "buy" it

export const GLOSSARY = {
  // Empty cells
  castle: {
    symbol: "\ue486",
    element: "castle",
    findersKeepers: false,
    storable: false,
  },
  earth: {
    number: 2,
    element: "earth",
    findersKeepers: false,
    storable: false,
    description: `Earth cells can be placed on this`,
    replaceVibe: function () {
      return 3;
    },
  },
  water: {
    number: 3,
    symbol: "\uf773",
    color: COLOR.blue,
    element: "water",
    // cost: 3,
    findersKeepers: false,
    storable: false,
    description: `Water cells can be placed on this.`,
    replaceVibe: function () {
      return 3;
    },
    onGenerate: function () {
      const spread = (cell) => {
        for (const other of cell.adjacent("air")) {
          spread(other.set(this.glossaryType));
        }
      };
      spread(this);
    },
    onDamage: function () {
      return false;
    },
  },
  air: {
    number: 1,
    // color: COLOR.blue,
    element: "air",
    findersKeepers: false,
    storable: false,
    description: `Air cells can be placed on this.`,
    replaceVibe: function () {
      return 3;
    },
  },
  fire: {
    number: 4,
    symbol: "\uf06d",
    color: COLOR.orange,
    // cost: 0,
    element: "fire",
    findersKeepers: false,
    storable: false,
    priority: 21,
    description: `Burns nearby cells, then becomes earth.`,
    // onDamage: function (other) {
    //   this.animateDestroy();
    //   this.set(null);
    //   return false;
    // },
    onEveryTurn: async function () {
      await Promise.all(this.adjacentCells.map((other) => this.burn(other)));
      this.set(null);
      this.animateDestroy();
    },
  },
  // Filled cells
  Castle: {
    number: 7,
    // symbol: '\ue0de',
    symbol: "\ue486",
    element: "castle",
    cost: 0,
    storable: false,
    fireproof: true,
    description: `Protect yours. Damage the other one to win.`,
    adjacentVibe: function (place, other) {
      if (this.deck !== other.deck) {
        if (other.glossary?.tags?.includes("attack")) {
          return 300;
        }
        return 200;
      }
      if (!place.type) {
        return 90;
      }
    },
  },
  Exit: {
    symbol: "\ue486",
    element: "castle",
    cost: 0,
    priority: 500,
    // findersKeepers: false,
    storable: false,
    fireproof: true,
    onDamage: function () {
      this.animateDestroy();
      return false;
    },
    onEveryTurn: function () {
      const playerCell = this.adjacentCells.find((x) => x.isPlayer);
      if (!playerCell) return;
      this.setDeck(playerCell.deck);
      this.animateDestroy();
    },
  },
  Barrier: {
    number: 6,
    symbol: null,
    cost: 1,
    storable: false,
    findersKeepers: false,
    description: `Takes up space. No special abilities.`,
    vibe: function (place) {
      if (place.type === "Shield") {
        return -30;
      }
      if (place.type) {
        return -3;
      }
    },
  },
  Shield: {
    number: 8,
    symbol: "\uf132",
    cost: 3,
    fireproof: true,
    tags: ["strong"],
    description: `Becomes a Barrier when hit. Cannot be burned.`,
    vibe: function (place) {
      if (
        place.adjacentCells.some(
          (x) => x.type === "Castle" && x.deck === this.deck
        )
      ) {
        return 30;
      }
      if (place.type === "Barrier") {
        return 4;
      }
      return 0;
    },
    replaceVibe: function (other) {
      if (
        this.adjacentCells.some(
          (x) => x.type === "Castle" && x.deck === this.deck
        )
      ) {
        return -40;
      }
      if (other.type === "Barrier") {
        return -10;
      }
      return 0;
    },
    onDamage: function () {
      this.deck?.delete(this);
      this.animateDestroy();
      this.setType("Barrier");
      return false;
    },
  },
  Horse: {
    number: 9,
    symbol: "\uf7ab",
    cost: 2,
    priority: 10,
    tags: ["move", "stack", "neat"],
    description: `Moves forwards, pushing other cells.`,
    vibe: function (place) {
      const { forwardsMove } = place;
      if (forwardsMove?.type === null) {
        return 10;
      }
      if (this.friendlyTo(forwardsMove)) {
        return 9;
      }
    },
    adjacentVibe: function (place, other) {
      if (place === this.forwardsMove && this.friendlyTo(other)) {
        if (other.type !== this.type) {
          return 15;
        }
        return 20;
      }
    },
    replaceVibe: function (other) {
      const { forwardsMove } = this;
      if (this.hostileTo(forwardsMove)) {
        return 40;
      }
    },
    onCreate: function () {
      this.meta.hasMoved = false;
    },
    onTurn: async function () {
      await this.move({
        push: true,
      });
    },
  },
  Sword: {
    number: 10,
    symbol: "\uf71c",
    cost: 2,
    priority: 6,
    recycle: true,
    tags: ["attack", "stack"],
    description: `Attacks forwards.`,
    vibe: function (place) {
      const { forwardsMove } = place;
      if (forwardsMove?.type === null) {
        return 10;
      }
      if (this.hostileTo(forwardsMove)) {
        return 15;
      }
    },
    adjacentVibe: function (place, other) {
      if (place === this.forwardsMove) {
        if (other.type === this.type) {
          return 30;
        }
        return -5;
      }
    },
    replaceVibe: function (other) {
      const { forwardsMove } = this;
      if (
        forwardsMove?.type &&
        forwardsMove?.type !== this.type &&
        this.friendlyTo(forwardsMove)
      ) {
        return 40;
      }
    },
    onTurn: function () {
      return this.attack();
    },
  },
  PairOfSwords: {
    number: 11,
    symbol: "\uf71d",
    cost: 4,
    priority: 6,
    tags: ["attack", "strong"],
    description: `Attacks three forwards directions at once.`,
    vibe: function (place) {
      return [place.forwards, place.forwardsLeft, place.forwardsRight].reduce(
        (sum, x) => {
          if (x?.type === null) {
            return sum + 10;
          }
          if (this.hostileTo(x)) {
            return sum + 15;
          }
          return sum - 10;
        },
        0
      );
    },
    adjacentVibe: function (place, other) {
      if (
        place === this.forwardsLeft ||
        place === this.forwardsLeft ||
        place === this.forwardsRight
      ) {
        return -5;
      }
    },
    replaceVibe: function (other) {
      const { forwardsMove } = this;
      if (forwardsMove?.type && this.friendlyTo(forwardsMove)) {
        return 40;
      }
    },
    onTurn: function () {
      // Destroy adjacent enemies (three directions)
      return Promise.all([
        this.damage(this.forwardsLeft),
        this.damage(this.forwards),
        this.damage(this.forwardsRight),
      ]);
    },
    onDamage: function () {
      this.deck?.delete(this);
      this.animateDestroy();
      this.setType("Sword");
      this.deck?.add(this.type);
      return false;
    },
  },
  Dagger: {
    number: 12,
    symbol: "\uf6cb",
    recycle: true,
    // giftable: false,
    cost: 2,
    priority: 8,
    tags: ["attack", "move"],
    description: `Moves forwards repeatedly, then attacks, destroying itself.`,
    vibe: function (place) {
      const { forwardsMove } = place;
      if (forwardsMove?.type === null) {
        return 10;
      }
      if (this.hostileTo(forwardsMove)) {
        return 9;
      }
    },
    adjacentVibe: function (place, other) {
      if (place === this.forwardsMove && other.type !== this.type) {
        return -5;
      }
    },
    replaceVibe: function (other) {
      const { forwardsMove } = this;
      if (forwardsMove?.type && this.friendlyTo(forwardsMove)) {
        return 40;
      } else {
        return -15;
      }
    },
    onTurn: async function () {
      await this.move({
        repeat: true,
        stack: false,
      });
      let other = this.forwardsMove;
      if (!this.hostileTo(other)) {
        other = this.forwardsDamage;
        if (!this.hostileTo(other)) {
          return;
        }
      }
      await Promise.all([this.damage(other), this.destroy()]);
    },
  },
  Mine: {
    number: 13,
    symbol: "\ue4e9",
    // color: COLOR.orange,
    cost: 1,
    priority: 2,
    // findersKeepers: false,
    frequency: 1,
    tags: ["attack"],
    description: `Damages everything nearby when an enemy is nearby.`,
    vibe: function (place) {
      const { adjacentCells } = place;
      const castle = adjacentCells.find((x) => x.type === "Castle");
      if (castle) {
        if (this.hostileTo(castle)) {
          return 100;
        }
        return -200;
      }
      return adjacentCells.reduce(
        (sum, x) => sum + 10 * ((this.deck !== x.deck) + this.hostileTo(x)),
        0
      );
    },
    adjacentVibe: function (place, other) {
      if (this.hostileTo(other)) {
        return -10;
      }
    },
    replaceVibe: function (other) {
      if (this.adjacentCells.every((x) => this.friendlyTo(x))) {
        return 10;
      }
    },
    onEveryTurn: function () {
      // Look for nearby enemies
      if (
        this.adjacentCells.some(
          (x) => x.type && x.deck !== this.deck && x.element !== "air"
        )
      ) {
        return this.damage(this, true);
      }
    },
    onDamage: async function () {
      this.destroy();
      await randomSleep(500);
      // Take out all adjacent cells
      await Promise.all(this.adjacentCells.map((x) => this.damage(x, true)));
    },
    onBurn: async function () {
      this.damage(this, true);
      return false;
    },
  },
  Treasure: {
    number: 5,
    symbol: "\uf723",
    color: COLOR.green,
    cost: 0,
    findersKeepers: false,
    storable: false,
    fireproof: true,
    // giftable: true,
    description: `Reveals a random cell when damaged.`,
    onDamage: async function (other) {
      // When damaged, reveal treasure to the attacker
      const { deck } = other;
      this.animateDestroy();
      this.set(this.type, deck);
      await sleep(500);
      this.setType(
        choose(
          UNLOCKABLE_TYPES.filter(
            (x) => (GLOSSARY[x].element ?? "ground") === "ground"
          )
        )
      );
      if (deck) {
        deck.add(this);
        this.creator = deck;
      }
      await sleep(500);
      return false;
    },
  },
  Dove: {
    number: 14,
    symbol: "\uf4ba",
    cost: 2,
    element: "air",
    priority: 9,
    tags: ["attack", "move", "stack"],
    description: `Moves forwards. When colliding, attacks and destroys itself.`,
    onCreate: function () {
      this.meta.hasMoved = false;
    },
    onTurn: async function () {
      await this.move();
      const other = this.forwardsDamage;
      if (this.hostileTo(other) && other?.glossaryType !== "air") {
        const { q, r } = this;
        await Promise.all([this.damage(other), this.destroy()]);
        this.q = q;
        this.r = r;
        this.calculateXY();
      }
    },
  },
  Arrow: {
    number: 15,
    symbol: "\uf176",
    enemySymbol: "\uf175",
    recycle: true,
    cost: 2,
    element: "air",
    priority: 8,
    tags: ["attack", "move"],
    description: `Moves forwards repeatedly, then attacks.`,
    onTurn: async function () {
      let other = this.forwardsMove;
      for (; other && !this.threatLevel(other); other = this.forwardsMove) {
        this.swapWith(other);
        Sound.play("move");
        await sleep(250);
        // Attack things that are directly in front of this
        if (this.hostileTo(this.forwards)) {
          other = this.forwards;
          break;
        }
      }
      if (!this.hostileTo(other)) {
        other = this.forwardsDamage;
        if (!this.hostileTo(other)) {
          await this.destroy();
          return;
        }
      }
      await Promise.all([this.damage(other), this.destroy()]);
    },
  },
  Bridge: {
    number: 16,
    symbol: "\ue4cd",
    cost: 1,
    element: "air",
    priority: 20,
    // limit: 3,
    tags: ["transform"],
    description: `Turns into earth.`,
    onTurn: function () {
      this.deck?.discard(this);
      this.animateDestroy();
      this.set("earth", null);
      Sound.play("place");
    },
  },
  Campfire: {
    number: 17,
    symbol: "\uf6ba",
    cost: 3,
    // recycle: true,
    // giftable: false,
    biome: "air",
    priority: 1,
    frequency: 1,
    fireproof: true,
    tags: ["fire", "attack", "spread"],
    description: `Burns nearby enemies.`,
    onEveryTurn: async function () {
      await Promise.all(
        this.adjacentCells
          .filter((x) => x.deck !== this.deck)
          .map((x) => this.burn(x))
      );
    },
    onDamage: async function () {
      this.set("fire", null);
      await this.animateDestroy();
      await Promise.all(this.adjacentCells.map((x) => this.burn(x)));
      this.set(null);
      await this.animateDestroy();
    },
  },
  RainCloud: {
    number: 18,
    symbol: "\uf738",
    cost: 4,
    element: "air",
    biome: "water",
    priority: -5,
    frequency: 2,
    tags: ["spread", "transform"],
    description: `Fills air with water.`,
    onTurn: function () {
      const spread = async (cell) => {
        await sleep(250);
        await Promise.all(
          cell.adjacent("air").map((other) => {
            other.setType("water");
            other.animateDestroy();
            Sound.play("place");
            return spread(other);
          })
        );
      };
      this.deck?.delete(this);
      this.animateDestroy();
      this.set("water", null);
      Sound.play("place");
      return spread(this);
    },
  },
  Anchor: {
    number: 19,
    symbol: "\uf13d",
    recycle: true,
    cost: 2,
    element: "water",
    priority: 12,
    frequency: 4,
    // limit: 1,
    tags: ["move"],
    description: `Moves forwards repeatedly. Destroys itself next turn.`,
    adjacentVibe: function (place, other) {
      if (!this.adjacentCells.some((x) => x.deck === this.deck)) {
        return 60;
      }
    },
    onTurn: async function () {
      const start = {
        q: this.q,
        r: this.r,
      };
      await this.move({ repeat: true, stack: false });
      if (this.q !== start.q || this.r !== start.r) return;
      await this.destroy();
    },
  },
  Sailboat: {
    number: 20,
    symbol: "\ue445",
    cost: 3,
    element: "water",
    priority: 11,
    tags: ["move", "stack"],
    description: `Moves forwards.`,
    onCreate: function () {
      this.meta.hasMoved = false;
    },
    onTurn: async function () {
      await this.move();
    },
  },
  Acorn: {
    number: 21,
    symbol: "\uf6ae",
    cost: 5,
    frequency: 1,
    tags: ["transform"],
    description: `Turns into a Tree on opponent's turn.`,
    adjacentVibe: function (place, other) {
      if (place === this.forwardsMove) {
        return -20;
      }
    },
    onEnemyTurn: function () {
      this.animateDestroy();
      this.setType("Tree");
      Sound.play("place");
      return sleep(500).then(() => this.onEnemyTurn?.());
    },
    onBurn: function (...args) {
      return GLOSSARY.Tree.onBurn.call(this, ...args);
    },
  },
  Tree: {
    number: 22,
    symbol: "\uf400",
    cost: 5,
    storable: false,
    tags: ["spread"],
    description: `Creates Acorns in three directions on opponent's turn.`,
    onEnemyTurn: async function () {
      await Promise.all(
        [this.forwards, this.forwardsLeft, this.forwardsRight].map(
          async (cell) => {
            await randomSleep(500);
            if (cell?.type !== null || !this.similarElement(cell)) return;
            cell.animateDestroy();
            cell.set("Acorn", this.deck);
            Sound.play("place");
          }
        )
      );
    },
    onBurn: async function () {
      this.set("fire", null);
      this.animateDestroy();
      await this.spread((other) => {
        if (other.type !== "Tree" && other.type !== "Acorn") {
          return false;
        }
        other.set("fire", null);
        other.animateDestroy();
      });
    },
    onDamage: function () {
      this.animateDestroy();
      this.setType("Barrier");
      return false;
    },
  },
  Frog: {
    number: 23,
    symbol: "\uf52e",
    cost: 3,
    tags: ["move", "utility"],
    description: `Jumps forwards over a cell and steals it.`,
    vibe: function (place) {
      const forwards = place.forwardsMove;
      if (!forwards) return -20;
      const other =
        this.isPlayer === forwards.isPlayer
          ? forwards.forwardsMove
          : forwards.backwardsMove;
      if (!other) {
        return -40;
      }
      if (forwards.type && !other.type) {
        if (this.hostileTo(forwards)) {
          return 60;
        }
        return 40;
      }
      if (other?.type) {
        return -20;
      }
    },
    onTurn: async function () {
      const start = {
        q: this.q,
        r: this.r,
      };
      const jumpedOver = this.forwardsMove;
      await this.move({
        stack: false,
        gait: 2,
      });
      if (this.q === start.q && this.r === start.r) return;
      if (!jumpedOver?.type) return;
      jumpedOver.setDeck(this.deck);
    },
  },
  Battery: {
    number: 24,
    symbol: "\uf0e7",
    cost: 6,
    bonusCells: 2,
    tags: ["utility"],
    description: `Adds two extra energy every turn.`,
  },
  Train: {
    number: 25,
    symbol: "\uf238",
    cost: 5,
    priority: 11,
    tags: ["move", "transform"],
    description: `Moves forwards repeatedly, converting cells to earth.`,
    onTurn: async function () {
      let other = this.forwards;
      for (
        ;
        other && (!this.threatLevel(other) || other.element !== "earth");
        other = this.forwards
      ) {
        this.swapWith(other);
        Sound.play("move");
        other.set(null);
        this.animateDestroy();
        await sleep(250);
      }
      Sound.play("move");
      this.deck?.delete(this);
      this.set(null);
      this.animateDestroy();
    },
  },
  Shovel: {
    number: 26,
    symbol: "\uf713",
    cost: 4,
    priority: 4,
    tags: ["move", "neat"],
    description: `Digs through filled earth, except fireproof cells`,
    onTurn: async function () {
      for (
        let other = this.forwardsSolid;
        other?.type && !other?.glossary?.fireproof && other?.type !== this.type;
        other = this.forwardsSolid
      ) {
        this.swapWith(other);
        Sound.play("move");
        this.animateDestroy();
        await sleep(250);
      }
    },
  },
  FishingRod: {
    number: 27,
    symbol: "\ue3a8",
    cost: 3,
    priority: 2,
    recycle: true,
    element: "water",
    tags: ["move", "utility", "neat"],
    description: `Moves forwards repeatedly, steals a cell, returns.`,
    onTurn: async function () {
      const start = {
        q: this.q,
        r: this.r,
        element: this.element,
      };
      const path = [];
      await this.move({
        stack: false,
        repeat: true,
        aggressive: true,
        onStep: (other) => path.push(other),
      });
      path.push(this);
      const other = this.forwardsDamage ?? this.forwards;
      const otherDeck = other?.deck ?? null;
      if (other?.type && this.deck !== other.deck) {
        other.setDeck(this.deck);
        other.animateDestroy();
      }
      await sleep(250);
      await this.destroy();
      if (!other?.type || other.element === "castle") return;
      const { element } = other;
      other.element = start.element;
      // And return to the original location
      for (const step of path.reverse()) {
        other.swapWith(step);
        if (step === this) {
          if (other.glossary?.tags?.includes("strong")) {
            this.set("Barrier", otherDeck);
          } else {
            this.setType(element);
          }
        }
        Sound.play("move");
        await sleep(250);
      }
      other.element = element;
    },
  },
  Hand: {
    number: 28,
    symbol: "\uf256",
    cost: 3,
    priority: 2,
    recycle: true,
    tags: ["move", "utility", "neat"],
    description: `Moves forwards repeatedly, steals a cell, returns.`,
    onTurn: function () {
      return GLOSSARY.FishingRod.onTurn.call(this);
    },
  },
  Parry: {
    number: 29,
    symbol: "\ue665",
    cost: 4,
    priority: 1,
    tags: ["attack", "strong", "utility"],
    fireproof: true,
    description: `Deflects one damage to its attacker when hit.`,
    onDamage: async function (other) {
      this.deck?.delete(this);
      this.animateDestroy();
      await sleep(500);
      this.setType("Barrier");
      await this.damage(other);
      return false;
    },
  },
  Eraser: {
    number: 30,
    symbol: "\uf307",
    cost: 0,
    priority: 50,
    giftable: false,
    recycle: true,
    tags: ["utility"],
    description: `Deletes itself at the start of the turn.`,
    onTurn: function () {
      this.animateDestroy();
      this.set(null);
    },
  },
  Scythe: {
    number: 31,
    symbol: "\uf710",
    cost: 3,
    priority: 5,
    tags: ["attack", "move", "stack"],
    description: `Attacks forwards, then moves forwards.`,
    onTurn: async function () {
      await this.attack();
      await sleep(500 + (this.isPlayer ? -1 : 1) * 75 * this.r);
      await this.move();
    },
  },
  Extender: {
    number: 32,
    symbol: "\ue4b4",
    cost: 2,
    priority: 40,
    // limit: 6,
    tags: ["spread", "utility", "neat"],
    description: `Damages forwards when hit by an ally.`,
    onFriendlyFire: async function () {
      await sleep(250);
      const { forwardsDamage } = this;
      if (this.hostileTo(forwardsDamage)) {
        await this.attack({
          stack: false,
        });
        return;
      }
      const { forwards, forwardsLeft, forwardsRight } = this;
      const friendly = [forwards, forwardsLeft, forwardsRight].filter(
        (x) => x?.onFriendlyFire
      );
      if (!friendly.length) friendly.push(forwardsDamage);
      await Promise.all(friendly.map((x) => this.damage(x)));
    },
    onTurn: async function () {
      await this.spread((other) => {
        if (other.type !== "Barrier" || other.deck !== this.deck) {
          return false;
        }
        other.setType(this.type);
        other.animateDestroy();
      });
    },
  },
  Repeater: {
    number: 33,
    symbol: "\uf363",
    cost: 4,
    priority: -20,
    parallel: false,
    tags: ["utility", "stack", "neat"],
    description: `Every ally touching this repeats its action once.`,
    onTurn: async function () {
      if (!this.deck) return;
      const repeatable = this.contiguousAllies.filter(
        (x) => x.type !== this.type
      );
      await this.deck.doTurn({
        cells: repeatable,
      });
    },
  },
  Rocket: {
    number: 34,
    symbol: "\ue027",
    cost: 5,
    priority: -2,
    recycle: true,
    element: "air",
    tags: ["fire", "attack", "move", "neat"],
    description: `Moves forwards repeatedly, then burns everything nearby.`,
    onTurn: async function () {
      await this.move({ repeat: true, aggressive: true });
      await this.destroy();
      await Promise.all(
        this.adjacentCells.map(async (x) => {
          if (x?.glossaryType === "earth") {
            x.set("fire");
            x.animateDestroy();
            await sleep(500);
            return;
          }
          await this.burn(x);
        })
      );
    },
  },
  Nuke: {
    number: 35,
    symbol: "\uf7b9",
    cost: 6,
    priority: -50,
    element: "air",
    tags: ["fire", "attack", "move", "utility"],
    description: `Moves forwards. Burns every single cell on collision.`,
    onTurn: async function () {
      const before = {
        q: this.q,
        r: this.r,
      };
      await this.move();
      const hasMoved = this.q !== before.q || this.r !== before.r;
      if (hasMoved && !this.hostileTo(this.forwardsDamage)) {
        return;
      }

      await sleep(1000);
      this.animateDestroy();
      this.set("fire", null);
      return this.spread((other) => {
        if (other.element !== "earth" || other.glossary?.fireproof) {
          return;
        }
        other.set("fire", null);
        other.animateDestroy();
      });
    },
  },
  Medic: {
    number: 36,
    symbol: "\uf0f1",
    cost: 4,
    priority: 1,
    tags: ["move", "utility", "neat"],
    description: `Swaps places with the closest allied Grave.`,
    onEveryTurn: async function () {
      if (!this.deck) return;
      let injured = null;
      await this.spread((other, { cancel }) => {
        if (injured) return false;
        if (other.meta.oldInjury) return;
        if (!this.wouldMourn(other)) return;
        injured = other;
        injured.meta.oldInjury = true;
        cancel();
      }, 5);
      if (!injured) return;
      this.animateDestroy();
      injured.animateDestroy();
      await sleep(500);
      this.swapWith(injured);
      injured.isRecovering = true;
      injured.setLayers();
      Sound.play("move");
    },
  },
  Extinguisher: {
    number: 37,
    symbol: "\uf134",
    cost: 5,
    priority: 20,
    fireproof: true,
    tags: ["fire", "spread", "utility"],
    description: `Puts out fires that are connected to this.`,
    onEveryTurn: async function () {
      await this.spread((other) => {
        if (other.glossaryType !== "fire") return false;
        other.set(null);
        other.animateDestroy();
      });
    },
  },
  Crosshairs: {
    number: 38,
    symbol: "\uf05b",
    cost: 4,
    priority: 12,
    tags: ["attack"],
    description: `Attacks one cell two ahead.`,
    onTurn: async function () {
      const next = (cell) => {
        if (!cell) return;
        if (this.isPlayer === cell.isPlayer) {
          return cell.forwardsMove;
        } else {
          return cell.backwardsMove;
        }
      };

      const other = next(next(this));
      await this.damage(other);
    },
  },
  Buzzsaw: {
    number: 39,
    symbol: "\uf863",
    cost: 5,
    priority: 10,
    tags: ["attack"],
    description: `Attacks every nearby cell.`,
    onTurn: async function () {
      const pairs = [
        [this.forwards, this.backwards],
        [this.forwardsLeft, this.backwardsRight],
        [this.backwardsLeft, this.forwardsRight],
      ]
        .map((x) => x.filter(Boolean))
        .filter((x) => x.length);
      await Promise.all(
        pairs.map(async (cells, i) => {
          await sleep(75 * i);
          await Promise.all(cells.map((cell) => this.damage(cell)));
        })
      );
    },
  },
  Axe: {
    number: 40,
    symbol: "\uf6b2",
    cost: 6,
    priority: 8,
    tags: ["attack", "move"],
    description: `Moves repeatedly, attacks every nearby cell, destroys itself.`,
    onTurn: async function () {
      await this.move({
        stack: false,
        repeat: true,
      });
      await sleep(500);
      const cells = [
        this.forwards,
        this.forwardsRight,
        this.backwardsRight,
        this.backwards,
        this.backwardsLeft,
        this.forwardsLeft,
      ].filter(Boolean);
      if (!cells.some((x) => this.hostileTo(x))) {
        return;
      }
      await Promise.all(
        cells.map(async (cell, i) => {
          await sleep(150 * i);
          await this.damage(cell);
        })
      );
      await this.destroy();
    },
  },
  Crab: {
    number: 41,
    symbol: "\ue3ff",
    cost: 3,
    priority: 5,
    tags: ["attack", "move", "stack", "neat"],
    description: `Damages any cell forwards and backwards, then moves.`,
    onTurn: async function () {
      const directions = [
        [this.forwardsDamage, this.forwardsMove],
        [this.backwardsDamage, this.backwardsMove],
      ];
      await Promise.all(
        directions.map((cells) => {
          let [cell] = cells;
          if (!cell || (!cell.type && !this.similarElement(cell))) {
            cell = cells[1];
          }
          if (
            !cell ||
            (this.friendlyTo(cell) &&
              (cell.glossary?.fireproof || cell.type === this.type))
          ) {
            // eslint-disable-next-line array-callback-return
            return;
          }
          return this.damage(cell, true);
        })
      );
      await sleep(500);
      await this.move({ push: true });
    },
  },
  Bomb: {
    number: 42,
    symbol: "\uf1e2",
    cost: 3,
    priority: 3,
    tags: ["attack", "move", "fire"],
    description: `Moves forwards. Explodes when hitting an enemy.`,
    onFriendlyFire: async function () {
      this.damage(this, true);
    },
    onDamage: async function () {
      this.destroy();
      await randomSleep(500);
      // Take out all adjacent cells
      await this.spread(async (other, { path }) => {
        if (path.length === 0) {
          await this.damage(other, true);
        } else if (path.length === 1) {
          await this.burn(other, true);
          return false;
        }
      });
    },
    onBurn: async function () {
      this.damage(this, true);
      return false;
    },
    onTurn: async function () {
      await this.move();
    },
    onEveryTurn: function () {
      // Look for nearby enemies
      if (this.adjacentCells.some((x) => this.hostileTo(x))) {
        return this.damage(this, true);
      }
    },
  },
  Spider: {
    number: 43,
    symbol: "\uf717",
    cost: 4,
    priority: 3,
    parallel: false,
    tags: ["attack", "move", "neat"],
    description: `Moves through allies to attack nearest enemy.`,
    onTurn: async function () {
      let target, path;
      await this.spread((other, etc) => {
        if (!this.hostileTo(other)) {
          if (other.glossary?.fireproof) return false;
          if (other.type === this.type) return false;
          if (other.element !== this.element) return false;
          return other.deck === this.deck;
        }
        etc.cancel();
        target = other;
        path = etc.path;
      }, 0);
      if (!target) return;
      // Follow the path to the target
      for (const other of path) {
        this.swapWith(other);
        Sound.play("move");
        await sleep(250);
      }
      // Attack them
      await this.damage(target);
      if (!this.type) return;
      // And return to the original perch
      for (const other of path.reverse()) {
        this.swapWith(other);
        Sound.play("move");
        await sleep(250);
      }
    },
  },
  Snake: {
    number: 44,
    symbol: "\uf716",
    priority: 11,
    cost: 3,
    tags: ["move", "stack", "neat"],
    description: `Moves forwards, pushing other cells. Leaves behind Barriers.`,
    onTurn: async function () {
      await this.move({
        push: true,
        onStep: (other, cell) => {
          if (cell !== this) return;
          // other.set(this.type, this.deck);
          // other.move({
          //   gait: 0,
          // });
          if (other.type) return;
          other.set("Barrier", this.deck);
        },
      });
    },
  },
  Grave: {
    number: 99,
    symbol: "\uf720",
    // cost: 0,
    storable: false,
    giftable: false,
    description: `RIP. Cells cannot be placed here this turn.`,
  },
};

export const UNLOCKABLE_TYPES = Object.freeze(
  Object.keys(GLOSSARY).filter((type) => GLOSSARY[type].storable !== false)
);

export const ELEMENTS = Object.freeze([
  ...new Set(Object.values(GLOSSARY).map((x) => x.element || "earth")),
]);

window.UNLOCKABLE_TYPES = UNLOCKABLE_TYPES;
