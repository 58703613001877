import Grid from "./grid";
import Cell from "./cell";
import { choose, irandom, repeat } from "./util";
import { MIN_Q, MAX_Q, MIN_R, MAX_R } from "./constants";
import { GLOSSARY } from "./glossary";

export const BIOMES = Object.freeze([
  {
    id: "earth",
    tags: ["earth"],
    weight: 3,
    // condition: () => false,
    generate: () => {
      const topNodes = [MIN_Q + irandom(2), 0, MAX_Q - irandom(2)];
      const bottomNodes = [MIN_Q + irandom(2), 0, MAX_Q - irandom(2)];
      // if (irandom(2)) {
      //   topNodes.splice(irandom(3), 1);
      //   bottomNodes.splice(irandom(3), 1);
      // }

      for (let i = topNodes.length; i--; ) {
        const start = { q: topNodes[i], r: MIN_R };
        const end = { q: bottomNodes[i], r: MAX_R };
        Grid.addLine({
          start,
          end,
        });
      }

      Grid.addRandom();
    },
  },
  {
    id: "water",
    tags: ["earth", "water"],
    generate: () => {
      const topNodes = [MIN_Q + irandom(2), 0, MAX_Q - irandom(2)];
      const bottomNodes = [MIN_Q + irandom(2), 0, MAX_Q - irandom(2)];
      const remove = irandom(2);
      topNodes.splice(remove, 1);
      bottomNodes.splice(remove, 1);

      for (let i = topNodes.length; i--; ) {
        const start = { q: topNodes[i], r: MIN_R };
        const end = { q: bottomNodes[i], r: MAX_R };
        Grid.addLine({
          start,
          end,
        });
      }

      Grid.addRandom({
        type: "water",
        count: 5,
        replaceFilter: (x) => x.glossaryType === "air",
      });
      Grid.addRandom({
        count: 5,
      });
    },
  },
  {
    id: "air",
    tags: ["earth", "air"],
    // condition: (Game) =>
    //   Game.focusTypes.includes((x) => isElement(x, "air")) ||
    //   Game.player.starting.includes((x) => isElement(x, "earth")),
    generate: () => {
      const topNodes = [MIN_Q + irandom(2), 0, MAX_Q - irandom(2)];
      const bottomNodes = [MIN_Q + irandom(2), 0, MAX_Q - irandom(2)];
      const remove = irandom(2);
      topNodes.splice(remove, 1);
      bottomNodes.splice(remove, 1);

      for (let i = topNodes.length; i--; ) {
        const start = { q: topNodes[i], r: MIN_R };
        const end = { q: bottomNodes[i], r: MAX_R };
        Grid.addLine({
          start,
          end,
        });
      }

      Grid.addRandom({
        count: 10,
      });
    },
  },
  {
    id: "siege",
    tags: ["earth"],
    // condition: () => !irandom(5),
    generate: (Game) => {
      basedOn(Game, "earth");

      // const type = choose(["Barrier", "Horse", "Sword"]);
      const type = "Barrier";
      const deck = Game.opponent;
      for (let q = MIN_Q; q <= MAX_Q; q++) {
        const r = -q + (q % 2);
        if (Grid.get(q, r) === null) continue;
        Grid.set(q, r, new Cell(type, { deck }));
      }
    },
  },
  {
    id: "volcano",
    tags: ["earth", "fire"],
    generate: (Game) => {
      basedOn(Game, "earth");

      Grid.addCircle({
        center: {
          q: 0,
          r: 0,
        },
        radius: 3,
        fill: false,
      });

      Grid.get(-1, -1).set("Campfire", Game.opponent);
      Grid.get(0, -2).set("Campfire", Game.opponent);
      Grid.get(1, -2).set("Campfire", Game.opponent);
      Grid.get(0, -1).set("Shield", Game.opponent);

      Grid.get(0, 1).set("Shield", Game.player);
      Grid.get(-1, 2).set("Campfire", Game.player);
      Grid.get(0, 2).set("Campfire", Game.player);
      Grid.get(1, 1).set("Campfire", Game.player);
    },
  },
  {
    id: "forest",
    tags: ["earth", "fire"],
    generate: (Game) => {
      basedOn(Game, "earth");

      for (const { r, deck } of [
        { r: MIN_R, deck: Game.opponent },
        { r: MAX_R, deck: Game.player },
      ]) {
        Grid.addCircle({
          center: {
            q: 0,
            r,
          },
          radius: 3,
          type: "Tree",
          deck,
          skipFilled: false,
        });
      }

      addToDecks(Game, "Campfire", 1);
    },
  },
  {
    id: "web",
    tags: ["earth"],
    generate: (Game) => {
      basedOn(Game, "earth");

      for (const { r, deck } of [
        { r: MIN_R + 1, deck: Game.opponent },
        { r: MAX_R - 1, deck: Game.player },
      ]) {
        Grid.addCircle({
          center: {
            q: 0,
            r,
          },
          radius: 1,
          type: "Barrier",
          deck,
          skipFilled: false,
        });
        Grid.get(0, r).set("Spider", deck);
      }
    },
  },
  {
    id: "mad",
    tags: ["earth", "air", "fire"],
    // weight: 2,
    generate: (Game) => {
      for (const q of [-1, 0, 1]) {
        Grid.addLine({
          start: {
            q,
            r: MIN_R,
          },
          end: {
            q,
            r: MAX_R,
          },
        });
      }

      Grid.get(MAX_Q, MIN_R).set("Nuke", Game.opponent);
      Grid.get(MIN_Q, MAX_R).set("Nuke", Game.player);
    },
  },
  {
    id: "station",
    tags: ["earth", "air"],
    // weight: 2,
    generate: (Game) => {
      for (const r of [MIN_R, MAX_R]) {
        Grid.addCircle({
          center: {
            q: 0,
            r,
          },
          radius: 3,
        });
      }
      // Center
      Grid.get(0, MIN_R + 3).set("Train");
      Grid.get(0, MAX_R - 3).set("Train");
      // Train corners
      Grid.get(MAX_Q, MIN_R).set("Train");
      Grid.get(MIN_Q, MAX_R).set("Train");
      // Rain corners
      Grid.get(MIN_Q, MIN_R - MIN_Q).set("RainCloud");
      Grid.get(MAX_Q, MAX_R - MAX_Q).set("RainCloud");
    },
  },
  {
    id: "race",
    tags: ["earth"],
    // condition: (Game) =>
    //   Game.focusTypes.includes("Horse") ||
    //   Game.player.starting.includes("Horse"),
    generate: (Game) => {
      basedOn(Game, "earth");

      for (const r of [MIN_R, MAX_R]) {
        const deck = r < 0 ? Game.opponent : Game.player;
        Grid.addCircle({
          center: {
            q: 0,
            r,
          },
          radius: 2,
          type: "Barrier",
          deck,
        });
        Grid.addCircle({
          center: {
            q: 0,
            r,
          },
          radius: 3,
          type: "Horse",
          deck,
        });
        const barriers = deck.placed.filter((x) => x.type === "Barrier");
        for (const cell of barriers) {
          cell.set("earth");
        }
      }
    },
  },
  {
    id: "battery",
    tags: ["earth"],
    generate: (Game) => {
      basedOn(Game, "earth");

      for (const r of [MIN_R, MAX_R]) {
        const deck = r < 0 ? Game.opponent : Game.player;
        Grid.addCircle({
          center: {
            q: 0,
            r,
          },
          radius: 1,
          type: "Battery",
          deck,
          skipFilled: false,
        });
        Grid.addCircle({
          center: {
            q: 0,
            r,
          },
          radius: 3,
          type: "Barrier",
          deck,
        });
        const barriers = deck.placed.filter((x) => x.type === "Barrier");
        for (const cell of barriers) {
          cell.set("earth");
        }
      }
    },
  },
  {
    id: "islands",
    tags: ["water"],
    generate: (Game) => {
      const topNodes = [MIN_Q, MAX_Q];
      const bottomNodes = [MIN_Q, MAX_Q];

      for (let i = topNodes.length; i--; ) {
        const start = { q: topNodes[i], r: MIN_R };
        const end = { q: bottomNodes[i], r: MAX_R };
        Grid.addLine({
          start,
          end,
        });
      }

      Grid.addRandom({
        count: 9,
        adjacentFilter: (x) =>
          x.glossaryType === "air" && Math.abs(x.q) < MAX_Q - 2,
        replaceFilter: (x) => x.glossaryType === "air",
      });
      for (const cell of Grid.existing) {
        if (cell.glossaryType !== "air") continue;
        cell.set("water");
      }
    },
  },
  {
    id: "waterfall",
    tags: ["water", "air"],
    generate: (Game) => {
      addToDecks(Game, "Anchor");

      Grid.addLine({
        start: {
          q: 0,
          r: MIN_R,
        },
        end: {
          q: 0,
          r: MAX_R,
        },
        type: "water",
      });
      for (const q of [-1, 1]) {
        Grid.addLine({
          start: {
            q,
            r: MIN_R,
          },
          end: {
            q,
            r: MAX_R,
          },
          type: "earth",
        });
      }

      Grid.addRandom({
        count: 9,
        replaceFilter: (x) => x.glossaryType === "air",
      });
    },
  },
  {
    id: "bridge",
    tags: ["air"],
    // condition: () => !irandom(5),
    generate: (Game) => {
      basedOn(Game, "air");
      addToDecks(Game, "Bridge");

      Grid.addLine({
        start: {
          q: MIN_Q,
          r: 0,
        },
        end: {
          q: MAX_Q,
          r: 0,
        },
        type: "air",
        skipFilled: false,
      });
    },
  },
  {
    id: "pepper",
    tags: ["earth"],
    // condition: () => !irandom(5),
    generate: (Game) => {
      basedOn(Game, "earth");

      Grid.addRandom({
        type: "Barrier",
        // adjacentFilter: x => x.glossaryType === 'earth',
        replaceFilter: (x) => !x.adjacentCells.some((x) => x.type),
      });

      const barriers = Grid.filter((x) => x.type === "Barrier");
      for (const barrier of barriers) {
        if (barrier.r <= 0) {
          barrier.setDeck(Game.opponent);
        } else {
          barrier.setDeck(Game.player);
        }
      }
    },
  },
]);

const basedOn = (Game, biomeId) => {
  BIOMES.find((x) => x.id === biomeId)?.generate(Game);
};

const addToDecks = (Game, type, count = 3) => {
  for (const deck of [Game.player, Game.opponent]) {
    deck.cells.unshift(...repeat(type, count));
    deck.shuffle();
  }
};

const isElement = (type, element) => {
  return (GLOSSARY[type]?.element ?? "earth") === element;
};
