export const SQRT_3 = Math.sqrt(3);
export const HALF_SQRT_3 = SQRT_3 / 2;

export const CELL_BOX_SIZE = 32;
export const CELL_BORDER = 3;
export const CELL_MARGIN = 0;
export const CELL_SIZE = CELL_BOX_SIZE - CELL_MARGIN * 2;
export const CELL_FLUX = CELL_SIZE * 0.1;
export const CELL_SIZE_COS = CELL_SIZE * 0.5;
export const CELL_SIZE_SIN = CELL_SIZE * HALF_SQRT_3;
export const CELL_SHADOW_OFFSET = 3;
export const CELL = Object.freeze({
  BORDER: CELL_BORDER,
  BOX_SIZE: CELL_BOX_SIZE,
  MARGIN: CELL_MARGIN,
  FLUX: CELL_FLUX,
  SIZE: CELL_SIZE,
  SIZE_COS: CELL_SIZE_COS,
  SIZE_SIN: CELL_SIZE_SIN,
  SHADOW_OFFSET: CELL_SHADOW_OFFSET,
  LINE_DASH: [20, 3, 3, 3, 3, 3, 3, 3],
  CLEAR_LINE_DASH: [],
  DESTROY_DURATION: 1000,
  DESTROY_VELOCITY: 48,
});

export const ATTACK_THREAT_LEVEL = 3;

export const CAMERA_X = 0.5;
export const CAMERA_Y = 0.42;

export const WIDTH = 400;
export const HEIGHT = 800;

export const MAX_Q = 3;
export const MIN_Q = -MAX_Q;

export const MAX_R = 5;
export const MIN_R = -MAX_R;

export const MAX_S = MAX_R;
export const MIN_S = -MAX_S;

const H_PAD = 48;
const V_PAD = 20;
export const LEFT_X = -WIDTH * CAMERA_X + H_PAD;
export const RIGHT_X = WIDTH * CAMERA_X - H_PAD;
export const TOP_Y = -HEIGHT * CAMERA_Y + V_PAD;
export const BOTTOM_Y = HEIGHT * (1 - CAMERA_Y) - V_PAD;

// vertical center + horizontal + cores + random
export const GRID_SIZE =
  2 * MAX_R -
  6 + // unfilled vertical center
  (4 * MAX_Q - 4) + // unfilled horizontal lines
  2 * 4 + // cores & their initial cover
  2 * MAX_R + // approximate size of other vertical lines
  12; // true chaos

export const HAND_SIZE = 7;
export const HAND_BARRIER_SIZE = 4;
export const HAND_SPECIAL_SIZE = HAND_SIZE - HAND_BARRIER_SIZE;
export const MAX_TYPE_STACK = 3;

export const COLOR = Object.freeze({
  background: "#0e172a",
  playerBackground: "#0e172a",
  opponentBackground: "#260404",
  player: "#61dafb",
  opponent: "#ff9900",
  neutral: "#ffffff",
  hand: "#ffffff",
  invalid: "#ff6363",
  a: "#2beb54",
  b: "#ff300e",
  x: "#2787e9",
  y: "#ffffff",
  black: "black",
  white: "#ffffff",
  green: "#73d13d",
  blue: "#1890ff",
  orange: "#fa541c",
  gold: "#fadb14",
});

export const CSS_VARS = Object.freeze(
  Object.fromEntries(Object.entries(COLOR).map(([k, v]) => [`--${k}-color`, v]))
);

export const FONT = Object.freeze({
  CELL_SYMBOL: '900 21px "Font Awesome 6 Pro"',
  CELL_COST_ICON: (size) => `900 ${size}px "Font Awesome 6 Pro"`,
  CELL_COST_TEXT: (size) => `${size}px "Bungee", Roboto, Helvetica, sans-serif`,
});
