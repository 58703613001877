import Cell from "./cell";
import {
  choose,
  clamp,
  irandom,
  irange,
  randomSleep,
  repeat,
  sleep,
  allIn,
} from "./util";
import {
  MIN_Q,
  MAX_Q,
  MIN_R,
  MAX_R,
  MIN_S,
  MAX_S,
  CELL_FLUX,
  COLOR,
  CELL,
  HALF_SQRT_3,
  SQRT_3,
} from "./constants";
import { Game } from "./game";
import { Camera } from "./camera";
import { ELEMENTS, GLOSSARY } from "./glossary";
import Sound from "./sound";
import Layers from "./layers";
import Settings from "./settings";
import Save from "./save";

const Grid = new (class {
  constructor() {
    this.existing = [];
    this.hand = null;
    this.clear();
  }

  get json() {
    return {
      existing: this.existing?.map((x) => x.id) || [],
    };
  }
  set json(json) {
    if (!json) return;
    this.existing.splice(0);
    // TODO: ensure that this is properly cleaned out, if it's not empty?
    for (const id of json.existing) {
      const cell = Save.cells[id];
      // this.set(cell.q, cell.r, cell);
      this.cells[cell.q - MIN_Q][cell.r - MIN_R] = cell;
      this.existing.push(cell);
    }
  }

  get availableElements() {
    return new Set(this.existing.map((x) => x.element));
  }

  animateFlux(cell, magnitude = 6) {
    const animation = {
      start: performance.now(),
      duration: 2500,
      magnitude,
      xl: [cell.xl1, cell.xl2],
      xr: [cell.xr1, cell.xr2],
      yu: [cell.yu],
      yd: [cell.yd],
    };
    if (Math.random() < 0.5) {
      animation.yu.push(cell.y);
    } else {
      animation.yd.push(cell.y);
    }
    this.fluxAnimations.push(animation);
    Camera.shake(cell.x, cell.y);
    if (window.lastInputType === "gamepad") {
      window.clientGamepad?.vibrate();
    }
    // navigator.vibrate?.(25);
  }

  animateIn() {
    const castle = Game.player.placed[0];
    const now = performance.now();
    const animateIn = (cell, timestamp) => {
      if (cell.addedTimestamp && timestamp > cell.addedTimestamp) {
        return;
      }
      cell.addedTimestamp = timestamp;
      cell.setLayers();
      cell.adjacentCells.map((x) => animateIn(x, timestamp + 250));
    };
    animateIn(castle, now);
    castle.addedTimestamp = 0;
    castle.setLayers();
    return Promise.all(
      // eslint-disable-next-line array-callback-return
      this.existing.map((cell) => {
        if (!cell.type) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        sleep(cell.addedTimestamp - now).then(() => {
          cell.animateDestroy();
          Sound.play("place");
        });
      })
    );
  }

  clear() {
    Game.player.placed.splice(0);
    Game.opponent.placed.splice(0);
    for (const cell of this.existing) {
      cell.hide();
    }
    this.existing = [];
    this.fluxCacheX = new Map();
    this.fluxCacheY = new Map();
    this.fluxAnimations = [];
    this.cells = new Array(1 + MAX_Q - MIN_Q)
      .fill()
      .map(() => new Array(1 + MAX_R - MIN_R).fill(null));
    Layers.prune();
  }

  clearValidPlaces() {
    this.forEach((cell) => {
      cell.isValidPlace = false;
      cell.setLayers();
    });
  }

  set(q, r, cell) {
    const previous = this.get(q, r);
    if (previous === undefined) {
      return;
    }
    if (previous) {
      const index = this.existing.indexOf(previous);
      if (index !== -1) {
        this.existing.splice(index, 1);
      }
      previous.hide();
      previous.deck?.unplace(previous);
    }
    this.cells[q - MIN_Q][r - MIN_R] = cell;
    if (cell) {
      cell.q = q;
      cell.r = r;
      cell.s = -q - r;
      cell.calculateXY();
      cell.cacheXY();
      if (!this.existing.includes(cell)) {
        this.existing.push(cell);
        cell.show();
      }
      cell.deck?.place(cell);
      cell.claimAdjacent();
    }
    return cell;
  }

  get(q, r) {
    const s = -q - r;
    if (s < MIN_S || s > MAX_S) {
      return;
    }
    return this.cells[q - MIN_Q]?.[r - MIN_R];
  }

  generate(biome = Game.biome) {
    // Ensure that this is empty first
    this.clear();

    // Add air
    for (let q = MIN_Q; q <= MAX_Q; q++) {
      for (let r = MIN_R; r <= MAX_R; r++) {
        this.set(q, r, new Cell("air"));
      }
    }

    // Create horizontal stripes of top/bottom
    for (const r of [MIN_R, MAX_R]) {
      for (let q = MIN_Q; q <= MAX_Q; ++q) {
        if (Math.abs(q) <= 1) {
          continue;
        }
        let adjustedR = r;
        if (-q - r < MIN_S) {
          adjustedR = MAX_S - q;
        } else if (-q - r > MAX_S) {
          adjustedR = MIN_S - q;
        }
        this.set(q, adjustedR, new Cell());
      }
    }
    // Create cores & their initial protection
    for (const coreR of [MIN_R, MAX_R]) {
      const isPlayer = coreR > 0;
      const deck = Game[isPlayer ? "player" : "opponent"];
      this.set(0, coreR, new Cell("Castle", { deck }));
      this.addCircle({
        center: { q: 0, r: coreR },
        radius: 1,
        type: "Shield",
        deck,
      });
    }

    biome?.generate?.(Game);
    this.generateGifts();

    // Handle generate callbacks
    for (const cell of this.existing) {
      cell.glossary.onGenerate?.call(cell);
    }
  }

  // Generates a grid with just a player castle and an exit (used as a basis for
  // curiosities)
  generateEmpty(exitType = "Exit") {
    // Clear any previous cells
    this.clear();
    // Create player
    const { player } = Game;
    this.set(0, MAX_R, new Cell("Castle", { deck: player }));
    // Create exit
    this.set(0, MIN_R, new Cell(exitType));
    // Determine where the gaps are...
    const gaps = [];
    for (let q = MIN_Q; q <= MAX_Q; q++) {
      for (let r = MIN_R; r <= MAX_R; r++) {
        if (this.get(q, r) !== null) {
          continue;
        }
        gaps.push([q, r]);
        this.set(q, r, new Cell("air"));
      }
    }
  }

  generateGifts(biome = Game.biome) {
    // Happy birthday!
    const allowed = new Set(biome.tags ?? ["earth", "water", "air"]);

    const playerOwned = new Set(Game.player.starting);
    const giftEntries = Array.from(
      new Set([...playerOwned, ...Game.focusTypes])
    ).map((x) => [x, GLOSSARY[x]]);
    const gifts = Object.fromEntries(
      ELEMENTS.map((el) => [
        el,
        giftEntries
          .filter(
            ([, x]) =>
              allowed.has(x.biome ?? x.element ?? "earth") &&
              (x.element === el || (el === "earth" && !x.element)) &&
              ((x.storable !== false && x.giftable !== false) || x.giftable)
          )
          .map(([type, info]) =>
            repeat(type, playerOwned.has(type) ? 2 : info.repeat ?? 3)
          )
          .flat(),
      ]).filter(([, x]) => x.length)
    );
    const limits = Object.fromEntries(
      [...new Set(Object.values(gifts).flat())].map((x) => [
        x,
        GLOSSARY[x].limit ?? 2,
      ])
    );

    const giftables = this.filter(
      (x) =>
        !x.type && x.element in gifts && !x.adjacentCells.some((x) => x.deck)
    );
    for (let i = Math.min(giftables.length, irange(3, 5)); i--; ) {
      let index;
      if (!i) {
        index = giftables.findIndex((x) => Game.focusTypes.includes(x));
      } else if (irandom(10) < 6) {
        const place = choose(giftables.filter((x) => x.element === biome));
        if (place) {
          index = giftables.indexOf(place);
        }
      }
      if (index == null) {
        index = irandom(giftables.length);
      }
      const cell = giftables.splice(index, 1)[0];
      let gift;
      if (irandom(10) < 6) {
        gift = choose(
          gifts[cell.element].filter(
            (x) => (GLOSSARY[x].biome ?? GLOSSARY[x].element) === biome
          )
        );
      }
      if (!gift) {
        gift = choose(gifts[cell.element]);
      }
      if (--limits[gift] === 0) {
        const array = gifts[cell.element];
        let deleteIndex;
        while ((deleteIndex = array.indexOf(gift)) !== -1) {
          array.splice(deleteIndex, 1);
        }
      }
      if (!gift) {
        console.warn(`Could not find gift for element: %s`, cell.element);
      }
      cell.set(gift);
      // console.log(`Added gift (%s) at [%d, %d]`, cell.glossaryType, cell.q, cell.r);
    }
  }

  addCircle({
    center: { q: centerQ = 0, r: centerR = 0 },
    radius = 1,
    type = "earth",
    deck = null,
    fill = true,
    skipFilled = true,
  }) {
    const center = this.getPoint(centerQ, centerR);
    for (let q = MIN_Q; q <= MAX_Q; ++q) {
      for (let r = MIN_R; r <= MAX_R; ++r) {
        const existing = this.get(q, r);
        if (existing === undefined) continue;
        if (skipFilled && existing?.type) continue;
        if (existing?.element === "castle") continue;
        const p = this.getPoint(q, r);
        const distance = Math.sqrt(
          Math.pow(center.x - p.x, 2) + Math.pow(center.y - p.y, 2)
        );
        if (distance > 1 + radius) continue;
        if (!fill && distance < radius) continue;
        if (this.get(q, r) === undefined) continue;
        this.set(q, r, new Cell(type, { deck }));
      }
    }
  }

  addLine({
    start: { q: startQ = 0, r: startR = 0 },
    end: { q: endQ = 0, r: endR = 0 },
    type = "earth",
    deck = null,
    skipFilled = true,
  }) {
    const start = this.getPoint(startQ, startR);
    const end = this.getPoint(endQ, endR);
    // console.log(`From (${startX}, ${startY}) to (${endX}, ${endY})`);
    // const speed = 1;
    const angle = Math.atan2(end.y - start.y, end.x - start.x);
    const distance = Math.sqrt(
      Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
    );
    const speed = 1 / distance;
    for (
      let x = start.x, y = start.y;
      Math.sqrt(Math.pow(x - start.x, 2) + Math.pow(y - start.y, 2)) <
      distance + speed;
      x += speed * Math.cos(angle), y += speed * Math.sin(angle)
    ) {
      const q = Math.round(x / 1.5);
      const r = Math.round((y - HALF_SQRT_3 * q) / SQRT_3);
      // console.log(`Filling in (${q}, ${r})`);
      const existing = this.get(q, r);
      if (existing === undefined) continue;
      if (skipFilled && existing?.type) continue;
      if (existing?.element === "castle") continue;
      this.set(q, r, new Cell(type, { deck }));
    }
  }

  addRandom({
    count = 20,
    type = "earth",
    deck = null,
    adjacentFilter = (x) => x.glossaryType === "earth",
    replaceFilter = (x) => !x.type,
    skipFilled = true,
  } = {}) {
    for (let i = count; i--; ) {
      // Pick a random existing cell
      const cell = choose(this.existing.filter(adjacentFilter));
      // Determine which sides could be expanded
      const location = choose(
        [
          [0, -1],
          [0, 1],
          [-1, 1],
          [1, -1],
          [-1, 0],
          [1, 0],
        ].filter(([q, r]) => {
          const existing = this.get(cell.q + q, cell.r + r);
          if (existing === undefined) return false;
          if (!replaceFilter(existing)) return false;
          if (!skipFilled) return true;
          if (existing?.type !== null) return false;
          return true;
        })
      );
      if (!location) {
        continue;
      }
      const [q, r] = location;
      this.set(cell.q + q, cell.r + r, new Cell(type, { deck }));
    }
  }

  getPoint(q, r) {
    return {
      x: 1.5 * q,
      y: HALF_SQRT_3 * q + SQRT_3 * r,
    };
  }

  getValidPlaces(source) {
    const { cost, deck, element, type } = source;
    if (cost > deck.hexes) {
      return [];
    }
    return this.existing.filter(
      (cell) =>
        (cell.element === element &&
          cell.type &&
          cell.type !== "Castle" &&
          cell.type !== type &&
          cell.deck === deck) ||
        (allIn([element, "track"], cell.glossaryType) &&
          !cell.isRecovering &&
          cell.adjacentCells.some((x) => x.type && x.deck === deck))
    );
  }

  // This attempts to ensure that cells that should appear on top of
  // other cells are later than them in the array of existing cells.
  // (NOTE: This is not called every frame!)
  updateRenderOrder() {
    this.existing.sort((a, b) => {
      // Valid places?
      if (!a.isValidPlace && b.isValidPlace) {
        return -1;
      } else if (a.isValidPlace && !b.isValidPlace) {
        return 1;
      }
      // Both unfilled?
      if (!a.type && !b.type) {
        return a.y - b.y || a.x - b.x;
      }
      // Filled?
      if (!a.type && b.type) {
        return -1;
      } else if (a.type && !b.type) {
        return 1;
      }
      // TODO: Types
      return 0;
    });
  }

  placeCell(source, destination = null) {
    // Check whether the clientX/Y was over a valid place
    if (destination === null) {
      destination = this.closestTo(window.clientX, window.clientY);
    }
    const canPlace = destination?.isValidPlace;
    if (canPlace) {
      const { type } = destination;
      if (type) {
        destination.destroy(true);
      }
      destination.creator = source.deck;
      destination.setType(source.type);
      destination.setDeck(source.deck);
      const index = this.hand.remove(source);
      source.deck.play(destination, index);
      this.hand.cells.forEach((x) => x.setLayers());
      if (!type) {
        destination.animateDestroy();
      }
      Sound.play("place");
    } else if (
      this.hand.cells.some((x) => x.isSelected) ||
      window.lastInputType === "gamepad"
    ) {
      return;
    }
    this.clearValidPlaces();
    this.updateRenderOrder();
    this.updateGamepadOptions();
    if (canPlace && window.clientGamepad && this.hand.cells.length) {
      const first = this.hand.cells[0];
      window.clientGamepad.currentOption = [first.x, first.y];
      first.isHovered = true;
      first.setLayers();
    }
    if (destination) {
      destination.isHovered = false;
      destination.setLayers();
    }
    if (canPlace && source.isPlayer) {
      Game.setCanUndo?.(true);
    }
    return canPlace;
  }

  filter(callback) {
    return this.existing.filter(callback);
  }

  find(callback) {
    return this.existing.find(callback);
  }

  forEach(callback) {
    this.existing.forEach(callback);
  }

  closestTo(x, y, maxDistance = 1.5) {
    let current = null,
      currentDistance = maxDistance;
    this.forEach((cell) => {
      const distance = cell.distanceTo(x, y, true);
      if (distance > currentDistance) {
        return;
      }
      current = cell;
      currentDistance = distance;
    });
    return current;
  }

  update(timestamp) {
    this.updateFluxCache(timestamp);
    // Render Cells
    for (const cell of this.existing) {
      if (!cell) {
        continue;
      }
      cell.update(timestamp);
    }
  }

  async restartRun() {
    Sound.play("place");
    Game.player.setBackground?.();
    this.get(0, MIN_R).setType(null);
    const castle = this.get(0, MAX_R);
    castle.set("Castle", Game.player);
    castle.animateDestroy();
    await Camera.reset(2000);
    Game.reset();
    this.hand.generateGrid();
    this.updateFluxCache(performance.now());
    this.animateIn();
    await sleep(500);
    this.hand.startTurn(Grid.availableElements);
  }

  setWinner(winner) {
    let other, castle;
    if (winner === Game.player) {
      other = Game.opponent;
      castle = this.get(0, MIN_R);
      winner.add(other.starting[0]);
    } else {
      other = Game.player;
      castle = this.get(0, MAX_R);
    }
    // Avoid claiming adjacent cells
    castle.set("Castle", null);
    castle.deck = winner;
    castle.isRecovering = false;
    castle.setLayers();
    castle.animateDestroy();
    Sound.play("place");
    const takeOver = async (cell) => {
      if (cell.wonTimestamp) {
        return;
      }
      await sleep(250);
      // It's possible that another cell set this since the sleep
      if (cell.wonTimestamp) {
        return;
      }
      cell.wonTimestamp = 100 * Math.round(performance.now() / 100) + 1500;
      if (cell.type && cell.deck !== winner) {
        // Don't animate claiming an unstorable cell
        if (cell.glossary.storable === false && cell.type !== "Barrier") {
          cell.deck = null;
          cell.color = COLOR.invalid;
        } else {
          // Avoid claiming adjacent cells
          cell.deck = winner;
        }
        cell.setLayers();
        cell.animateDestroy();
        winner.add(cell);
        Sound.play("place");
      } else {
        cell.setLayers();
      }
      const promises = cell.adjacentCells.map((x) => takeOver(x));
      // If all of the pieces have been taken, don't wait for this before
      // continuing
      if (!other.placed.length) {
        await sleep(750);
        return;
      }
      await Promise.all(promises);
    };
    return takeOver(castle);
  }

  updateFluxCache(timestamp) {
    const FLUX = Settings.wobble ? CELL_FLUX : 0;
    const animatedX = new Map();
    const animatedY = new Map();
    for (
      let i = this.fluxAnimations.length, animation;
      (animation = this.fluxAnimations[--i]);

    ) {
      const t = (timestamp - animation.start) / animation.duration;
      if (t >= 1) {
        this.fluxAnimations.splice(i, 1);
        continue;
      }
      const lerp = Math.sin(t * 12);
      const magnitude = animation.magnitude * lerp * (1 - t);
      for (const x of animation.xl) {
        animatedX.set(x, clamp(-12, (animatedX.get(x) ?? 0) - magnitude, 12));
      }
      for (const x of animation.xr) {
        animatedX.set(x, clamp(-12, (animatedX.get(x) ?? 0) + magnitude, 12));
      }
      for (const y of animation.yu) {
        animatedY.set(y, clamp(-12, (animatedY.get(y) ?? 0) - magnitude, 12));
      }
      for (const y of animation.yd) {
        animatedY.set(y, clamp(-12, (animatedY.get(y) ?? 0) + magnitude, 12));
      }
    }
    for (const x of this.fluxCacheX.keys()) {
      this.fluxCacheX.set(
        x,
        x + (animatedX.get(x) ?? 0) + FLUX * Math.cos(2 * x + timestamp / 600)
      );
    }
    for (const y of this.fluxCacheY.keys()) {
      this.fluxCacheY.set(
        y,
        y + (animatedY.get(y) ?? 0) + FLUX * Math.sin(3 * y + timestamp / 750)
      );
    }
  }

  updateGamepadOptions() {
    if (!window.clientGamepad) {
      return;
    }
    const gamepad = window.clientGamepad;
    gamepad.options = [];
    gamepad.b.callback = null;
    if (Game.player.isInTurn) {
      // eslint-disable-next-line no-sequences
      gamepad.x.callback = () => (gamepad.b.callback?.(), this.hand.endTurn());
    } else {
      gamepad.x.callback = null;
    }
    if (this.hand.isGameOver) {
      gamepad.a.callback = () => {
        gamepad.a.callback = null;
        this.hand.restartRun();
      };
    }
    if (!Game.player.isInTurn || !this.hand.cells.length) {
      return;
    }
    const selected = this.hand.cells.find((x) => x.isSelected);
    if (selected) {
      gamepad.b.callback = () => {
        gamepad.currentOption = [selected.x, selected.y];
        window.clientX = selected.x;
        window.clientY = selected.y;
        // window.clientPressed = false;
        // window.clientHeld = false;
        // window.clientReleased = false;
        window.clientMoved = true;
        selected.isSelected = false;
        this.clearValidPlaces();
        this.updateGamepadOptions();
      };
      gamepad.options.push(
        ...this.existing
          // .filter(x => x.isValidPlace)
          .map((c) => [c.x, c.y])
      );
    } else {
      // Add all cells in the hand
      gamepad.options.push(...this.hand.cells.map((c) => [c.x, c.y]));
      // // Add end turn
      // gamepad.options.push([120, 420]);
    }
  }

  updateRecovery() {
    return Promise.all(
      this.existing.map(async (cell) => {
        if (!cell.isRecovering) {
          return;
        }
        await randomSleep(500, 300);
        if (!cell.type) {
          Sound.play("destroy");
          cell.animateDestroy();
          cell.isRecovering = false;
          delete cell.meta.oldInjury;
          cell.color = cell.glossary.color ?? COLOR.neutral;
          cell.setLayers();
        }
        await sleep(CELL.DESTROY_DURATION * 0.5);
      })
    );
  }
})();

// Testing helper
window.Grid = Grid;

export default Grid;
