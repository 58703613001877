const Save = new (class {
  get json() {
    return {
      state: this.state || "start",
      game: this.game || null,
      grid: this.grid || null,
      hand: this.hand || null,
      cells: this.cells || null,
    };
  }

  getStorage() {
    return JSON.parse(localStorage.getItem("hexyl-save") || "{}");
  }

  async load() {
    const current = this.json;
    const storage = this.getStorage();
    for (const key in current) {
      if (key in storage) {
        this[key] = storage[key];
      } else {
        this[key] = current[key];
      }
    }
  }

  gc() {
    for (const key in this.json) {
      if (key === "state") continue;
      delete this[key];
    }
  }

  saveStorage(json) {
    (window.requestIdleCallback ?? window.setTimeout)(() => {
      localStorage.setItem("hexyl-save", JSON.stringify(json));
    });
  }

  set(json) {
    const delta = {};
    const existing = this.json;
    for (const [name, value] of Object.entries(json)) {
      if (name in existing === false) continue;
      delta[name] = value;
    }
    // Do not save if we're in between turns
    if (json.game?.opponent?.isInTurn) {
      return;
    }
    this.saveStorage({
      ...existing,
      ...delta,
    });
  }

  reset() {
    localStorage.removeItem("hexyl-save");
    window.location.reload();
  }
})();

window.Save = Save;

export default Save;
