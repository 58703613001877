import { Deck } from "./deck";
import { MAX_TYPE_STACK } from "./constants";
import { GLOSSARY, UNLOCKABLE_TYPES } from "./glossary";
import { choose, shuffle } from "./util";

export const Game = new (class {
  constructor() {
    this.biome = null;
    this.curiosity = null;
    this.completedCuriosities = [];
    this.level = 1;
    this.renderNeeded = true;
    this.isFullscreen = Boolean(document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      this.isFullscreen = Boolean(document.fullscreenElement);
    });
    this.handY = 0;
    // Decks
    this.reset();
  }

  get json() {
    return {
      biome: this.biome?.id || null,
      curiosity: this.curiosity?.key,
      completedCuriosities: this.completedCuriosities,
      level: this.level,
      focus: this.focus,
      player: this.player.json,
      opponent: this.opponent.json,
    };
  }
  set json(json) {
    if (!json) return;
    this.biome = { id: json.biome };
    this.curiosity = { key: json.curiosity };
    this.completedCuriosities = json.completedCuriosities ?? [];
    this.level = json.level;
    this.focus = json.focus ?? [];
    this.player.json = json.player;
    this.opponent.json = json.opponent;
  }

  deckFromJson(name) {
    if (!name) return null;
    return this[name.toLowerCase?.()] || name || null;
  }

  get focusTypes() {
    const digit = this.level % 5;
    // Bosses and Curiosities have all of the focuses at once
    if (digit === 0 || digit === 4) {
      return this.focus;
    }
    // Other levels have one focus at a time
    const act = digit - 1;
    return this.focus.slice(act, act + 1);
  }

  get isBoss() {
    return this.level % 10 === 0;
  }

  /**
   * Fully reset both decks to their state for the start of the game
   */
  reset() {
    let { setBackground } = this.player || {};
    this.player = Deck.generate({ name: "Player", setBackground });
    ({ setBackground } = this.opponent || {});
    this.curiosity = {}; // Ensure that the first screen isn't a curiosity
    this.level = 1;
    this.generateStage();
    this.biome = null;
    this.opponent = Deck.generate({
      name: "Opponent",
      basedOn: this.player,
      focusTypes: this.focusTypes,
      level: this.level,
      setBackground,
    });
  }

  /**
   * Create a new opponent and get ready for the next match after player wins
   */
  generate() {
    this.player.reset();
    const previous = this.opponent;
    this.opponent = Deck.generate({
      name: previous.name,
      setBackground: previous.setBackground,
      isBoss: this.isBoss,
      level: this.level,
      basedOn: this.player,
      previous,
    });
  }

  /**
   * Generates common information for a set of 10 levels.
   */
  generateStage() {
    // Group unlockable cells by how few of them the player has
    const counts = {};
    for (const type of this.player.starting) {
      if (type in counts) {
        ++counts[type];
      } else {
        counts[type] = 1;
      }
    }
    const stage = Math.ceil(this.level / 5);
    const allowedElements = new Set(["earth"]);
    const allowedTags = new Set(["attack", "move", "stack"]);
    let minCost = 1;
    let maxCost = 4;
    if (stage >= 2) {
      allowedElements.add("water");
      allowedTags.add("strong");
      allowedTags.add("spread");
      allowedTags.add("transform");
      allowedTags.add("utility");
    }
    if (stage >= 3) {
      allowedElements.add("air");
      maxCost = 5;
    }
    if (stage >= 4) {
      allowedTags.add("fire");
      minCost = 0;
      maxCost = 6;
    }
    for (const element of allowedElements) {
      allowedTags.add(element);
    }
    // console.log({ stage, counts });
    const groups = new Map();
    for (const type of UNLOCKABLE_TYPES) {
      // Filter down which cells are possible for the current stage
      const info = GLOSSARY[type];
      if (info.cost < minCost || info.cost > maxCost) continue;
      const tags = [info.element ?? "earth", ...(info.tags ?? [])];
      if (!tags.every((x) => allowedTags.has(x))) continue;
      // Group cells by how opportune they are
      const count = counts[type] ?? 0;
      const max = info.limit ?? MAX_TYPE_STACK;
      const opportunity = (max - count) / max;
      let existing = groups.get(opportunity);
      if (!existing) {
        existing = [];
        groups.set(opportunity, existing);
      }
      existing.push(type);
    }

    // Get the groups as an array of arrays (sorted by most opportune)
    const sortedGroups = Array.from(groups.entries())
      .sort((a, b) => b[0] - a[0])
      .map((x) => x[1]);

    // Get a list of "neat" cell types
    const neatList = UNLOCKABLE_TYPES.filter((type) => {
      const info = GLOSSARY[type];
      if (info.cost < minCost || info.cost > maxCost) return false;
      if (!allowedElements.has(info.element ?? "earth")) return false;
      if (!info.tags?.includes("neat")) return false;
      if (type in counts) return false;
      return true;
    });
    const neat = choose(neatList);

    // console.log({ groups: sortedGroups.map((x) => x.slice()) });
    // console.log({ neat, neatList });

    // Pick 3 cells to focus on this stage
    const focus = [];
    const focusCount = 3;
    if (neat) focus.push(neat);
    while (focus.length < focusCount && sortedGroups.length) {
      const group = shuffle(sortedGroups.shift());
      // console.log({ group });
      focus.push(...group.splice(0, focusCount - focus.length));
    }
    this.focus = shuffle(focus);
  }

  /**
   * Checks whether one or both players have perished
   */
  getWinner() {
    // // TEMP: Testing!
    // return this.player;

    if (this.curiosity) {
      if (this.player.placed.some((x) => x.type === "Exit")) {
        return this.player;
      }
      if (!this.player.placed.some((x) => x.type === "Castle")) {
        return this.opponent;
      }
      return null;
    }

    if (!this.player.placed.some((x) => x.type === "Castle")) {
      return this.opponent;
    }
    if (!this.opponent.placed.some((x) => x.type === "Castle")) {
      return this.player;
    }
    return null;
  }

  async toggleFullscreen() {
    if (this.isFullscreen) {
      await document.exitFullscreen();
      return;
    }
    await document.querySelector("#root").requestFullscreen();
  }
})();

// Testing
window.Game = Game;
